<template>
	<div style="background:#f3f3f3;">
		<div class="tools">
			<button @click="pdf()" v-if="pageInfo.isTestAccount==0||this.$route.query.admin == 1">导出PDF</button>
		</div>
		<div class="report-page" id="report-page">
			<!--第一页：封面-->
			<div class="page page-one" id="page-one">
				<img src="../../assets/img/logo-08.svg" alt="北京心流慧估科技有限公司logo-心流" class="logoImage" />
				<div class="flowAndStock hide">
					<div style="height: 100px;margin-bottom: 96px;">
						<img class="flow" src="../../assets/img/logo-08.svg" />
						<span class="logoXian" style="margin: 0 15px;"></span>
						<img class="stock" src="" />
						<p class="reportNumber">报告编号：</p>
					</div>
				</div>
				<p class="reportNumber" v-text="'报告编号：' + pageInfo.reportNumber">报告编号：</p>
				<img src="../../assets/img/specialization/artFont.png" alt="" class="artFont">
				<div class="page-main">
					<p class="companyName" v-text="pageInfo.baseInfo.companyName"></p>
					<h5>估值报告书</h5>
					<h6>（专精特新版）</h6>
				</div>
				<div class="page-one-footer">
					<div class="date" v-text="pageInfo.createTime ? replaceAll(pageInfo.createTime) : ''">2018 .05. 08
					</div>
					<p>
						<span>北京心流慧估科技有限公司</span>
						<span>|</span>
						<span>心流保留对此报告的最终解释权</span>
					</p>
				</div>
			</div>
			<!--盖章页-->
			<div class="page page-stamp" id="page-stamp">
				<!--内容-->
				<div class="page-content">
					<p class="stamp">
						依据《企业会计准则第22号--金融工具确认和计量》、《企业会计准则第39号--公允价值计量》、《私募投资基金非上市股权投资估值指引》和《中小微企业估值规范》等政策规章，基于股权估值模型和估值指标体系，贵公司在估值基准日的股权价值为（人民币）：
					</p>
					<div class="detail_result">
						<!-- <img src="../../assets/img/specialization/artFont.svg" class="artFont" alt=""> -->
						<div>
							<img src="../../assets/img/specialization/artFont.svg" class="artFont" alt="">
							<h4 v-text="resultFormate(pageInfo.minValue, pageInfo.maxValue)">0.00</h4>
						</div>
					</div>
					<div class="stamptime">
						<p>北京心流慧估科技有限公司</p>
						<p v-text="pageInfo.updateTime ? dateFormate(pageInfo.updateTime) : dateFormate(pageInfo.createTime)">
						</p>
					</div>

				</div>
			</div>
			<!--目录页-->
			<div class="page page-catalogue" id="page-catalogue">
				<h2>目录</h2>
				<div class="generalize">
					<div>
						<p>1</p>
						<div></div>
						<p>基本信息</p>
						<span class="dottedLine titleActive"></span>
						<span class="pageNumber pageNumActive">01</span>
					</div>

					<div>
						<p>2</p>
						<div></div>
						<p>估值模型</p>
						<span class="dottedLine titleActive"></span>
						<span class="pageNumber pageNumActive">03</span>
					</div>
					<ul>
						<li>
							<p>相对估值法</p>
							<span style="width: 742px" class="dottedLine"></span>
							<span class="pageNumber">03</span>
						</li>
						<li>
							<p>绝对估值法</p>
							<span style="width: 742px" class="dottedLine"></span>
							<span class="pageNumber">04</span>
						</li>
					</ul>
					<div>
						<p>3</p>
						<div></div>
						<p>估值图谱</p>
						<span class="dottedLine titleActive"></span>
						<span class="pageNumber pageNumActive">05</span>
					</div>
					<ul class="flowChart">
						<li>
							<p>专业化（主营业务专业专注）</p>
							<span class="dottedLine" style="width: 599px;"></span>
							<span class="pageNumber">05</span>
						</li>
						<li>
							<p>精细化（经营管理精细高效）</p>
							<span class="dottedLine" style="width: 599px;"></span>
							<span class="pageNumber">06</span>
						</li>
						<li>
							<p>特色化（产品服务独具特色）</p>
							<span class="dottedLine" style="width: 599px;"></span>
							<span class="pageNumber">06</span>
						</li>
						<li>
							<p>新颖化（创新能力成果显著）</p>
							<span class="dottedLine" style="width: 599px;"></span>
							<span class="pageNumber">06</span>
						</li>
					</ul>
					<div>
						<p>4</p>
						<div></div>
						<p>估值结果</p>
						<span class="dottedLine titleActive"></span>
						<span class="pageNumber pageNumActive">07</span>
					</div>
					<div>
						<p>5</p>
						<div></div>
						<p>盈利预测</p>
						<span class="dottedLine titleActive"></span>
						<span class="pageNumber pageNumActive">08</span>
					</div>
					<ul>
						<li>
							<p>营业收入预测</p>
							<span style="width: 724px" class="dottedLine"></span>
							<span class="pageNumber">08</span>
						</li>
						<li>
							<p>净利润预测</p>
							<span style="width: 742px" class="dottedLine"></span>
							<span class="pageNumber">08</span>
						</li>
					</ul>
					<div>
						<p>6</p>
						<div></div>
						<p>财务分析</p>
						<span style="width: 734px;" class="dottedLine titleActive"></span>
						<span class="pageNumber pageNumActive">09</span>
					</div>
					<ul>
						<li>
							<p>偿债分析</p>
							<span class="dottedLine"></span>
							<span class="pageNumber">09</span>
						</li>
						<li>
							<p>盈利分析</p>
							<span class="dottedLine"></span>
							<span class="pageNumber">10</span>
						</li>
						<li>
							<p>成长分析</p>
							<span class="dottedLine"></span>
							<span class="pageNumber">11</span>
						</li>
						<li>
							<p>营运分析</p>
							<span class="dottedLine"></span>
							<span class="pageNumber">12</span>
						</li>
						<li>
							<p>杜邦分析</p>
							<span class="dottedLine"></span>
							<span class="pageNumber">13</span>
						</li>
					</ul>
					<div>
						<p>7</p>
						<div></div>
						<p>体检结果</p>
						<span class="dottedLine titleActive"></span>
						<span class="pageNumber pageNumActive">14</span>
					</div>
					<div>
						<p>8</p>
						<div></div>
						<p>附件</p>
						<span style="width: 784px;" class="dottedLine titleActive"></span>
						<span class="pageNumber pageNumActive">15</span>
					</div>
					<div class="isAnd_catalogue">
						<p class="andOne">9</p>
						<div></div>
						<p>关于心流</p>
						<span class="dottedLine titleActive"></span>
						<span class="pageNumber pageNumActive isAnd_ andOne">20</span>
					</div>
				</div>
			</div>
			<!--第二页-->
			<div class="page page-two" id="page-two">
				<!-- 页面内容-->
				<div class="page-content">
					<!--估值结果-->
					<div class="valueResult">
						<div class="valueResultTitle">
							<img src="../../assets/img/guzhi/valueResult.png" class="icon_" alt="">
							<p>估值结果</p>
						</div>
						<div class="valueResultCont">
							<div class="valueResultPic">
								<p v-text="resultFormate(pageInfo.minValue, pageInfo.maxValue)">0.00</p>
							</div>
							<h6>报告基准日：<span class="reportBaseDate" v-text="replaceAll(pageInfo.reportBaseDate)">1000年0月0日</span></h6>
							<div class="resultDes">
							</div>
						</div>
					</div>
					<div class="baseInfo">
						<div class="baseInfoTitle">
							<img src="../../assets/img/startup/jibenxinxi.png" class="icon_ icon_1" alt="">
							<p>企业基本信息</p>
						</div>
						<div class="baseInfoCont">
							<ul>
								<li>
									<span>企业名称：</span>
									<span class="companyName" v-text="pageInfo.baseInfo.companyName"></span>
								</li>
								<li>
									<span>企业所在地：</span>
									<span class="companyLocation"
										v-text="pageInfo.baseInfo.area ? pageInfo.baseInfo.area:'无数据'">无数据</span>
								</li>
								<li>
									<span>统一社会信用代码：</span>
									<span class="socialCreditCode"
										v-text="pageInfo.baseInfo.creditCode ? pageInfo.baseInfo.creditCode:'无数据'"></span>
								</li>
								<li>
									<span>法定代表人：</span>
									<span class="corporateRepresentative"
										v-text="pageInfo.baseInfo.legalPeople ? pageInfo.baseInfo.legalPeople:'无数据'"></span>
								</li>
								<li>
									<span>邮箱：</span>
									<span class="Email" v-text="pageInfo.baseInfo.email ? pageInfo.baseInfo.email:'无数据'"></span>
								</li>
								<li>
									<span>成立日期：</span>
									<span class="establishmentDate"
										v-text="pageInfo.baseInfo.establishDate ? dateFormate(pageInfo.baseInfo.establishDate): '无数据'"></span>
								</li>
								<li>
									<span style="vertical-align: top;">所属行业：</span>
									<span class="industry" style="width: 298px;"
										v-text="pageInfo.baseInfo.industryName + '-' + pageInfo.baseInfo.industryChildName">无数据</span>
								</li>
								<li>
									<span>企业价值（自评）：</span>
									<span class="companyValuation" v-text="valueFormate(pageInfo.baseInfo.selfValue)"></span>
								</li>
							</ul>
						</div>
					</div>
					<div class="mainBusiness">
						<div class="mainBusinessTitle">
							<img src="../../assets/img/startup/zhuyingyewu.png" alt="" class="icon_ icon_2">
							<p>主营业务</p>
						</div>
						<div class="mainBusinessCont">
							<p class="bussinessDesc" v-text="pageInfo.baseInfo.bussinessDesc">无数据</p>
						</div>
					</div>
					<!-- 发展情况 + 企业家素质 -->
					<div class="productAndGroup">
						<!-- 发展情况 -->
						<div class="product">
							<div class="product_Title" style="margin: 0;">
								<img src="../../assets/img/startup/chanpin.png" alt="" class="icon_ icon_3">
								<p>发展情况</p>
							</div>
							<div class="productCont">
								<ul>
									<li>
										<span>所处周期：</span>
										<span class="productStage">成长期</span>
									</li>
									<li>
										<span>产品推广：</span>
										<span class="productType"
											v-text="pageInfo.baseInfo.extendMarketFlag == 1 ? '已推广到市场' : '未推广到市场'"></span>
									</li>
									<li>
										<span>盈亏情况：</span>
										<span class="clientFeelback" v-text="pageInfo.profitLoss?pageInfo.profitLoss:'无数据'"></span>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<!-- 企业基本信息-->

					<!--主营业务-->
				</div>
				<div class="pageNum">
					<p>///</p><span>1</span>
				</div>
			</div>
			<!-- 基本信息第二页 -->
			<div class="page page-two-second" id="page-two-second">
				<div class="page-content">
					<!--  -->
					<div class="productAndGroup">
					</div>
					<div class="financingSituation">
						<div class="situationTitle">
							<img src="../../assets/img/startup/rongzi.png" alt="" class="icon_ icon_5">
							<p>拟融资</p>
						</div>
						<div class="situationCont">
							<ul>
								<li>
									<span>拟融资额度：</span>
									<span class="financingLimit"
										v-text="pageInfo.baseInfo.finance ? formatCurrency.formateNum(pageInfo.baseInfo.finance)+'万元' : '无数据'"></span>
								</li>
								<li>
									<span>计划出让股权：</span>
									<span class="concessionStock"
										v-text="pageInfo.baseInfo.ratio ? pageInfo.baseInfo.ratio + '%' : '无数据'"></span>
								</li>
							</ul>
						</div>
					</div>
					<!-- 股权结构 -->
					<div class="stockStructure">
						<div class="structureTitle">
							<img src="../../assets/img/startup/guquan.png" alt="" class="icon_ icon_6">
							<p>股权结构</p>
						</div>
						<Draw2DPieCanvas :id="'stockStructurePie'" :chartData="pageInfo.baseInfo.holderRation" v-if="initStatus" />
					</div>
					<div style="margin-top: 160px;">
						<!-- 心流诊断图谱 -->
						<div class="flowDiagnose">
							<div class="flowDiagnoseTitle">
								<!-- <img src="../../assets/img/startup/xinliutupu.svg" alt="" class="icon1"> -->
								<img src="../../assets/img/guzhi/xinliuPic.png" alt="" class="icon_ icon1">
								<p>估值图谱</p>
							</div>
							<DrawRadarCanvas2v :id="'flowDiagnose_BaseInfo'" :chartData="pageInfo.question" v-if="initStatus" />
						</div>
						<!-- 财务分析 -->
						<div class="financeAnalyze">
							<div class="financeAnalyzeTitle">
								<img src="../../assets/img/guzhi/caiwufenxi.png" alt="" class="icon_ icon7">
								<p>财务分析</p>
							</div>
							<DrawRadarCanvas2v :chartData="pageInfo.financial" :id="'financeAnalyze_BaseInfo'" v-if="initStatus" />
						</div>
					</div>
				</div>
				<div class="pageNum">
					<p>///</p><span>2</span>
				</div>
			</div>
			<!--第三页-->
			<div class="page page-three" id="page-three">
				<!-- 页面内容-->
				<div class="page-content">
					<!--相对估值法-->
					<div class="page-text">
						<div class="relativeValuation">
							<div class="relativeValuationTitle">
								<img src="../../assets/img/guzhi/relativePic.png" alt="" class="icon_">
								<p>相对估值法</p>
							</div>
						</div>
						<div class="page-text-content">
							<div class="relavtive-container">
								<div class="relavtive-left">
									<div class="relavtive-one" style="margin-left:-16px;">
										<DrawBarFinancialAnalysisRelativeX :id="'barOne'" v-if="initStatus"
											:chartData="pageInfo.relativeValuation.baseInfo" style="height:250px" />
									</div>
									<div class="relavtive-two">
										<DrawBarFinancialAnalysisRelativeX :id="'barTwo'" v-if="initStatus"
											:chartData="pageInfo.relativeValuation.valuationMul" style="height:250px" />
									</div>
								</div>
								<div class="relavtive-mid-container">
									<div class="relavtive-mid"></div>
									<div class="amount"></div>
								</div>
								<div class="relavtive-right">
									<p>相对估值</p>
									<div class="relavtive-three">
										<DrawBarFinancialAnalysisRelativeY :id="'barThree'" v-if="initStatus"
											:chartData="pageInfo.relativeValuation.valuationValue" style="height:360px" />
									</div>
								</div>
							</div>
							<div class="clear"></div>
							<div class="relative-result">
								<p>(相对估值法)估值结果</p>
								<span class="relaResult" v-text="valueFormate(pageInfo.relativeValuationValue)">12,122.45万元</span>
							</div>
							<div class="page-tip-container">
								<ul>
									<li>
										<label>1、</label>
										<p class="tipStrOne" v-text="relativeText"></p>
									</li>
									<li>
										<label>2、</label>
										<p>相对估值法是系统根据“上市公司比较法”（Guideline Public Company
											Method，GPCM），以可比公司在市场上的当前定价为基础，评估企业价值。</p>
									</li>
									<li>
										<label>3、</label>
										<p>市盈率、市净率、市销率、市现率所取数值为系统对A股、新三板、港股、纳斯达克等板块数据进行分行业整理、计算得出，根据企业
											所处行业分类选取。其他资料详见附件。</p>
									</li>
									<li>
										<label>4、</label>
										<p>当市盈率法、市净率法、市销率法、市现率法的估值结果为负值或空值，则该估值指标不参与计算。</p>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<!-- 页脚-->
				<div class="pageNum">
					<p>///</p><span>3</span>
				</div>
			</div>
			<!--第四页-->
			<div class="page page-four" id="page-four">
				<!--绝对估值法标题-->
				<div class="absoluteValuation">
					<div class="absoluteValuationTitle">
						<img src="../../assets/img/guzhi/absolutePic.png" alt="" class="icon_">
						<p>绝对估值法</p>
					</div>
				</div>
				<!--表格部分-->
				<div class="table_area">
					<DrawPileBar :id="'cashFlowforecast'" class="cash-flow" :chartData="pageInfo.absoluteValuation"
						:year="nowYear" v-if="initStatus" style="height: 480px" />
					<div class="cash-flow center-arrow">
						<img src="../../assets/img/report/equal@2x.png" alt="线上估值-心流" />
					</div>
					<DrawBarLine :id="'cashFlowTrend'" class="cash-flow" :chartData="pageInfo.absoluteValuation" :year="nowYear"
						v-if="initStatus" style="height: 480px" />
				</div>
				<!--虚线框部分-->
				<div class="dashes-line"></div>
				<!--箭头指示-->
				<div class="arrow-down">
					<p>WACC<br /><span></span></p>
				</div>
				<div class="border-area">
					<p>(绝对估值法)估值结果</p>
					<p class="result-absolute"
						v-text="pageInfo.absoluteValuation.ggm?valueFormate(pageInfo.absoluteValuation.ggm):''"></p>
				</div>
				<div class="explain-container">
					1、绝对估值法：系统采用DCF(Discount Cash Flow)中FCFF(Free Cash Flow to Firm)模型和GGM (Gorden Growth
					Model)模型相结合，对企业无杠杆自由现金流及终值进行折现，继而对企业进行价值评估。
					<br /> 2、在对企业终值测算时，系统采用GGM(Gorden Growth Model)模型。
					<br /> 3、WACC(加权平均资本成本)是系统综合考虑企业所在行业特性，杠杆比率，股权成本，债务成本等因素后，计算出的总资本成本。<br />
					4、当FCFF计算估值结果为负值时，则该估值方法不适用。
				</div>
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>4</span>
				</div>
			</div>
			<!--第五页-->
			<div class="page page-five" id="page-five">
				<!--分值说明-->
				<div class="socre-explain-container">
					<p>
						<img src="../../assets/img/report/page03/score@2x.png" alt="专业估值报告中的分值说明-心流" />
						<span>分值说明</span>
					</p>
					<p>
						<span id="socre-explain" v-text="scoreText"></span>
					</p>
				</div>
				<!--行业-->
				<div class="industryTitle">
					<img src="../../assets/img/startup/hangyedefen.png" alt="" class="icon_">
					<p>专业化（主营业务专业专注）</p>
				</div>
				<div class="page-text">
					<div class="page-text-content industryEnvirContent">
						<!-- 图-->
						<!-- <div class="industryEnvirChart chart" data-isX="true" id="industryEnvirChart"></div> -->
						<DrawGaugePic :id="'industryEnvirChart_0'" v-if="initStatus" :chartData="pageInfo.industry"
							:chartName="pageInfo.industry[0].name" :chartValue="pageInfo.industry[0].value"
							style="height:288px;width:33%;" class="chart" />
						<DrawGaugePic :id="'industryEnvirChart_1'" v-if="initStatus" :chartData="pageInfo.industry"
							:chartName="pageInfo.industry[1].name" :chartValue="pageInfo.industry[1].value"
							style="height:288px;width:33%;" class="chart" />
						<DrawGaugePic :id="'industryEnvirChart_2'" v-if="initStatus" :chartData="pageInfo.industry"
							:chartName="pageInfo.industry[2].name" :chartValue="pageInfo.industry[2].value"
							style="height:288px;width:33%;" class="chart" />
						<DrawGaugePic :id="'industryEnvirChart_3'" v-if="initStatus" :chartData="pageInfo.industry"
							:chartName="pageInfo.industry[3].name" :chartValue="pageInfo.industry[3].value"
							style="height:288px;width:33%;" class="chart" />
						<DrawGaugePic :id="'industryEnvirChart_4'" v-if="initStatus" :chartData="pageInfo.industry"
							:chartName="pageInfo.industry[4].name" :chartValue="pageInfo.industry[4].value"
							style="height:288px;width:33%;" class="chart" />
						<DrawGaugePic :id="'industryEnvirChart_5'" v-if="initStatus" :chartData="pageInfo.industry"
							:chartName="pageInfo.industry[5].name" :chartValue="pageInfo.industry[5].value"
							style="height:288px;width:33%;" class="chart" />
						<!--描述-->
						<div class="industryEnvirDescribe describe">
							<p v-for="(item,index) in industryStatements" :key="index + 3">{{item}}</p>
						</div>
					</div>
				</div>

				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>5</span>
				</div>
			</div>
			<!--第六页-->
			<div class="page page-six" id="page-six">
				<!-- 页面标题-->
				<div class="page-content">
					<div class="product_Title">
						<img src="../../assets/img/guzhi/gengduochanpin.png" alt="" class="icon_">
						<p>精细化（经营管理精细高效）</p>
					</div>
					<div class="page-text-content enterpriseContent">
						<!-- 图-->
						<DrawGaugePic :id="'enterpriseCompeChart_0'" v-if="initStatus" :chartData="pageInfo.competitivePower"
							:chartName="pageInfo.competitivePower[0].name" :chartValue="pageInfo.competitivePower[0].value"
							style="height:288px;width:50%;" class="chart" />
						<DrawGaugePic :id="'enterpriseCompeChart_1'" v-if="initStatus" :chartData="pageInfo.competitivePower"
							:chartName="pageInfo.competitivePower[1].name" :chartValue="pageInfo.competitivePower[1].value"
							style="height:288px;width:50%;" class="chart" />
						<!--描述-->
						<div class="enterpriseCompeDescribe describe">
							<p v-for="(item,index) in competitivePowerStatements" :key="index + 4">{{item}}</p>
						</div>
					</div>
					<!-- 团队-->
					<div class="page-text">
						<div class="teamTitle">
							<img src="../../assets/img/startup/tuanduidefen.png" alt="" class="icon_">
							<p>特色化（产品服务独具特色）</p>
						</div>
						<div class="page-text-content teamManageContent">
							<!-- 图-->
							<DrawGaugePic :id="'teamManageChart_0'" v-if="initStatus" :chartData="pageInfo.teamManage"
								:chartName="pageInfo.teamManage[0].name" :chartValue="pageInfo.teamManage[0].value"
								style="height:288px;width:50%;" class="chart" />
							<DrawGaugePic :id="'teamManageChart_1'" v-if="initStatus" :chartData="pageInfo.teamManage"
								:chartName="pageInfo.teamManage[1].name" :chartValue="pageInfo.teamManage[1].value"
								style="height:288px;width:50%;" class="chart" />
							<!--描述-->
							<div class="teamManageDescribe describe">
								<p v-for="(item,index) in teamManageStatements" :key="index + 4">{{item}}</p>
							</div>
						</div>
					</div>
					<!-- 技术-->
					<div class="page-text">
						<div class="skillTitle">
							<img src="../../assets/img/startup/jishudefen.png" alt="" class="icon_">
							<p>新颖化（创新能力成果显著）</p>
						</div>
						<div class="page-text-content technicalCompeContent">
							<!-- 图-->
							<DrawGaugePic :id="'technicalCompeChart_0'" v-if="initStatus" :chartData="pageInfo.technology"
								:chartName="pageInfo.technology[0].name" :chartValue="pageInfo.technology[0].value"
								style="height:288px;width:50%;" class="chart" />
							<DrawGaugePic :id="'technicalCompeChart_1'" v-if="initStatus" :chartData="pageInfo.technology"
								:chartName="pageInfo.technology[1].name" :chartValue="pageInfo.technology[1].value"
								style="height:288px;width:50%;" class="chart" />
							<!--描述-->
						</div>
					</div>
					<!-- 风控 -->

				</div>
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>6</span>
				</div>
			</div>
			<!--第七页-->
			<div class="page page-seven" id="page-seven">
				<div class="riskManageDesc">
					<p v-for="(item,index) in technologyStatements" :key="index + 5">{{item}}</p>
				</div>
				<!--估值方法-->
				<div class="valuation-result-container">
					<div class="valuation-result relative_result">
						<p>相对估值法估值结果 </p>
					</div>
					<div class="valuation-result absolute_result">
						<p>绝对估值法估值结果</p>
					</div>
					<div class="valuation-result liquidity_ratio ">
						<p>流动性折价比率 </p>
					</div>
					<div class="valuation-result revise_result">
						<p>心流修正指数倍数区间 </p>
					</div>
				</div>
				<img class="img_pageSeven_arrow" src="../../assets/img/report/result-img-02@2x.png" alt="专业估值报告中的估值结果-心流" />
				<div class="valuation-result-main">
					<p>估值结果</p>
					<p id="valuResult" v-text="resultFormate(pageInfo.minValue, pageInfo.maxValue)">
						12,122.45~13,674.56万元</p>
				</div>
				<p>企业最终的估值结果为综合考虑企业相对估值结果、绝对估值结果、流动性折价因素、修正指数倍数后得出。</p>
				<div class="result-explain-container page-tip-container">
					<p>注：</p>
					<ul>
						<li>
							<label>1、</label>
							<p>
								流动性折价率综合考虑了非上市企业股权转售时的流动性风险，系统根据不同行业制定差异性的流动性折价率；
							</p>
						</li>
						<li>
							<label>2、</label>
							<p>修正指数倍数区间是由影响企业价值的内外因素通过量化指标综合打分得出，包括软性指标和财务指标分析两部分，具体参照心流图谱和财务分析部分。</p>
						</li>
					</ul>
				</div>
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>7</span>
				</div>
			</div>
			<!--第八页-->
			<div class="page page-eight" id="page-eight">
				<!--营业收入预测标题-->
				<div class="expenditure_Title">
					<img src="../../assets/img/startup/shouru.png" alt="" class="icon_">
					<p>营业收入预测</p>
				</div>
				<!--营业收入预测表-->
				<DrawBarProfitForecast :id="'incomeForecast'" v-if="initStatus" :chartData="pageInfo.incomeForecast"
					:year="nowYear" />
				<!--净利润预测标题-->
				<div class="income_Title">
					<img src="../../assets/img/guzhi/jinglirun.png" alt="" class="icon_">
					<p>净利润预测</p>
				</div>
				<!--净利润预测表-->
				<DrawBarProfitForecast :id="'netProfitForecast'" v-if="initStatus" :chartData="pageInfo.netProfitForecast"
					:year="nowYear" />
				<!--底部说明-->
				<div class="bottom_explain">
					<span><img src="../../assets/img/report/page03/score@2x.png"
							alt="根据企业提供的历史财务数据，结合国际通用的财务预测方法及规则，对企业未来五年营业收入、净利润进行预测-心流"></span>
					<p>说明：根据企业提供的历史财务数据，结合国际通用的财务预测方法及规则，对企业未来五年营业收入、净利润进行预测。</p>
				</div>
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>8</span>
				</div>
			</div>
			<!--第九页-->
			<div class="page page-nine" id="page-nine">
				<!--营业收入预测标题-->
				<div class="debtPayTitle">
					<img src="../../assets/img/startup/zhichu.png" alt="" class="icon_">
					<p>偿债分析</p>
				</div>
				<!--偿债能力表格和文字说明部分-->
				<div class="table_explain sovency asset-liability-ratio">
					<div class="left sovency_table">
						<!--资产负债率-->
						<DrawBarFinancialAnalysisY :id="'liabilitiesRatio'" v-if="initStatus"
							:chartData="pageInfo.liabilitiesRatio" />
					</div>
					<div class="right sovency_explain">
						<div class="grade">
							<img class="battery" :src="liabilitiesRatioStatementSrc" alt="诊断报告中的偿债分析之资产负债表-心流" />
							<p v-if="initStatus" v-text="pageInfo.liabilitiesRatio.batteryLevel"
								:style="pageInfo.liabilitiesRatio.batteryLevel == '20%'?'color:#FF383D;':''"></p>
						</div>
						<h4 v-text="liabilitiesRatioStatement"></h4>
						<p v-for="(item,index) in liabilitiesRatioStatementArr" :key="index + 6">{{item}}</p>
					</div>

				</div>
				<div class="table_explain sovency liquidity-ratio">
					<div class="left sovency_table">
						<!--流动比率-->
						<DrawBarFinancialAnalysisY :id="'flowRation'" v-if="initStatus" :chartData="pageInfo.flowRation"
							style="width:500px;height:450px;" />
					</div>
					<div class="right sovency_explain">
						<div class="grade">
							<img class="battery" :src="flowRationStatementSrc" alt="诊断报告中的偿债分析之流动比率-心流" />
							<!-- <ul></ul> -->
							<p v-if="initStatus" v-text="pageInfo.flowRation.batteryLevel"
								:style="pageInfo.flowRation.batteryLevel=='20%'?'color:#FF383D;':''"></p>
						</div>
						<h4 v-text="flowRationStatement"></h4>
						<p v-for="(item,index) in flowRationStatementArr" :key="index + 5">{{item}}</p>
					</div>
				</div>
				<div class="table_explain sovency interest-cover">
					<div class="left sovency_table ebit">
						<DrawBarFinancialAnalysisY :id="'ebitMultiple'" v-if="initStatus" :chartData="pageInfo.ebitMultiple"
							style="width:500px;height:450px;" />
						<!-- EBIT利息保障倍数-->
					</div>
					<div class="right sovency_explain ebitr">
						<div class="grade" v-if="ebitMultipleGradeStatus">
							<img class="battery" :src="ebitMultipleStatementSrc" alt="诊断报告中的偿债分析之EBIT利息保障倍数-心流" />
							<!-- <ul></ul> -->
							<p v-if="initStatus" v-text="pageInfo.ebitMultiple.batteryLevel"
								:style="pageInfo.ebitMultiple.batteryLevel=='20%'?'color:#FF383D;':''"></p>
						</div>
						<h4 v-text="ebitMultipleStatement"></h4>
						<p v-for="(item,index) in ebitMultipleStatementArr" :key="index + 7">{{item}}</p>
					</div>
				</div>
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>9</span>
				</div>
			</div>
			<!--第十页-->
			<div class="page page-ten" id="page-ten">
				<!--盈利能力标题-->
				<div class="profitTitle">
					<img src="../../assets/img/guzhi/yinglifenxi.png" alt="" class="icon_">
					<p>盈利分析</p>
				</div>
				<!--盈利能力表格和文字说明部分-->
				<div class="table_explain profitability gross-profit-ratio">
					<div class="left profitability_table">
						<DrawBarFinancialAnalysisX :id="'grossProfitRatio'" v-if="initStatus" :chartData="pageInfo.grossProfitRatio"
							style="width:500px;height:450px;" />
						<!--毛利率-->
					</div>
					<div class="right profitability_explain">
						<div class="grade">
							<img class="battery" :src="grossProfitRatioStatementSrc" alt="诊断报告中的盈利分析之毛利率-心流" />
							<p v-if="initStatus" v-text="pageInfo.grossProfitRatio.batteryLevel"
								:style="pageInfo.grossProfitRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
						</div>
						<h4 v-text="grossProfitRatioStatement"></h4>
						<p v-for="(item,index) in grossProfitRatioStatementArr" :key="index + 7">{{item}}</p>
					</div>
				</div>
				<div class="table_explain profitability net-profit-rate">
					<div class="left profitability_table">
						<!--净利润率-->
						<DrawBarFinancialAnalysisX :id="'netProfiltRatio'" v-if="initStatus" :chartData="pageInfo.netProfiltRatio"
							style="width:500px;height:450px;" />
					</div>
					<div class="right profitability_explain">
						<div class="grade">
							<img class="battery" :src="netProfiltRatioStatementSrc" alt="诊断报告中的盈利分析之净利润率-心流" />
							<p v-if="initStatus" v-text="pageInfo.netProfiltRatio.batteryLevel"
								:style="pageInfo.netProfiltRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
						</div>
						<h4 v-text="netProfiltRatioStatement"></h4>
						<p v-for="(item,index) in netProfiltRatioStatementArr" :key="index + 8">{{item}}</p>
					</div>
				</div>
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>10</span>
				</div>
			</div>
			<!--第十一页-->
			<div class="page page-eleven" id="page-eleven">
				<!--成长能力标题-->
				<div class="growthTitle">
					<img src="../../assets/img/guzhi/chengzhangfenxi.png" alt="" class="icon_">
					<p>成长分析</p>
				</div>
				<!--成长能力表格和文字说明部分-->
				<div class="table_explain growUp main-business">
					<div class="left growUp_table">
						<!--主营业务收入增长率-->
						<DrawBarFinancialAnalysisY :id="'mainIncomeRatio'" v-if="initStatus" :chartData="pageInfo.mainIncomeRatio"
							style="width:500px;height:450px;" />
					</div>
					<div class="right growUp_explain">
						<div class="grade">
							<img class="battery" :src="mainIncomeRatioStatementSrc" alt="诊断报告中的成长分析之主营业务收入增长率-心流" />
							<p v-if="initStatus" v-text="pageInfo.mainIncomeRatio.batteryLevel"
								:style="pageInfo.mainIncomeRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
						</div>
						<h4 v-text="mainIncomeRatioStatement"></h4>
						<p v-for="(item,index) in mainIncomeRatioStatementArr" :key="index + 9">{{item}}</p>
					</div>
				</div>
				<div class="table_explain growUp  net-asset-ratio">
					<div class="left growUp_table">
						<!--净资产收益率-->
						<DrawBarFinancialAnalysisY :id="'netAssetRatio'" v-if="initStatus" :chartData="pageInfo.netAssetRatio"
							style="width:500px;height:450px;" />
					</div>
					<div class="right growUp_explain">
						<div class="grade">
							<img class="battery" :src="netAssetRatioStatementSrc" alt="诊断报告中的成长分析之净资产收益率-心流" />
							<!-- <ul></ul> -->
							<p v-if="initStatus" v-text="pageInfo.netAssetRatio.batteryLevel"
								:style="pageInfo.netAssetRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
						</div>
						<h4 v-text="netAssetRatioStatement"></h4>
						<p v-for="(item,index) in netAssetRatioStatementArr" :key="index + 10">{{item}}</p>
					</div>
				</div>
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>11</span>
				</div>
			</div>
			<!--第十二页-->
			<div class="page page-twelve" id="page-twelve">
				<!--成长能力标题-->
				<div class="operateTitle">
					<img src="../../assets/img/guzhi/yunyingfenxi.png" alt="" class="icon_">
					<p>营运分析</p>
				</div>
				<!--运营能力表格和文字说明部分-->
				<div class="table_explain operational account-turnover-rate">
					<div class="left operational_table">
						<!--应收账款周转率-->
						<DrawBarFinancialAnalysisX :id="'receivablesTurnoverRatio'" v-if="initStatus"
							:chartData="pageInfo.receivablesTurnoverRatio" style="width:500px;height:450px;" />
					</div>
					<div class="right operational_explain">
						<div class="grade" v-if="receivablesTurnoverRatioGradeStatus">
							<img class="battery" :src="receivablesTurnoverRatioStatementSrc" alt="诊断报告中的营运分析之应收账款周转率-心流" />
							<!-- <ul></ul> -->
							<p v-if="initStatus" v-text="pageInfo.receivablesTurnoverRatio.batteryLevel"
								:style="pageInfo.receivablesTurnoverRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
						</div>
						<h4 v-text="receivablesTurnoverRatioStatement"></h4>
						<p v-for="(item,index) in receivablesTurnoverRatioStatementArr" :key="index + 11">{{item}}</p>
					</div>
				</div>
				<div class="table_explain operational  inventory-turnover-ratio">
					<div class="left operational_table">
						<!--存货周转率-->
						<DrawBarFinancialAnalysisX :id="'inventoryTurnoverRatio'" v-if="initStatus"
							:chartData="pageInfo.inventoryTurnoverRatio" style="width:500px;height:450px;" />
					</div>
					<div class="right operational_explain">
						<div class="grade" v-if="inventoryTurnoverRatioGradeStatus">
							<img class="battery" :src="inventoryTurnoverRatioStatementSrc" alt="诊断报告中的营运分析之存货周转率-心流" />
							<!-- <ul></ul> -->
							<p v-if="initStatus" v-text="pageInfo.inventoryTurnoverRatio.batteryLevel"
								:style="pageInfo.inventoryTurnoverRatio.batteryLevel=='20%'?'color:#FF383D;':''"></p>
						</div>
						<h4 v-text="inventoryTurnoverRatioStatement"></h4>
						<p v-for="(item,index) in inventoryTurnoverRatioStatementArr" :key="index + 12">{{item}}</p>
					</div>
				</div>
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>12</span>
				</div>
			</div>
			<!--杜邦分析页-->
			<div class="page page-dupon" id="page-dupon">
				<!--杜邦分析标题-->
				<DupontAnalyze v-if="initStatus" :versions="0" :dupontData="pageInfo.dubangAnalysis" />
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>13</span>
				</div>
			</div>
			<!--第十三页-->
			<div class="page page-thirteen" id="page-thirteen">
				<!--成长能力标题-->
				<div class="financialConditionTitle">
					<img src="../../assets/img/guzhi/caiwuzhuangkuang.png" alt="" class="icon_">
					<p>财务状况体检报告</p>
				</div>
				<!--圆环内容-->
				<ul class="imgUl">
					<li v-for="(item,index) in pageInfo.financial" :key="index + 12">
						<div class="liDivImg" :style="'border-color:' + item.color">
							<i-circle :style="'font-weight:bold;color:' + item.color" :stroke-width="10" :trail-width="10"
								trail-color="#fff" :stroke-color="item.color" :size="150" class="circleComponent"
								:percent="Number(item.value)">
								{{item.value}}%
							</i-circle>
						</div>
						<h3 id="indicatorContainer1_title" :style="'color:' + item.color">{{item.name}}</h3>
						<p id="indicatorContainer1_content">{{item.description}}</p>
					</li>
					<div class="clear"></div>
				</ul>
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>14</span>
				</div>
			</div>
			<!--第十四页-->
			<div class="page page-fourteen" id="page-fourteen">
				<!-- 页面内容-->
				<LibTableComponent1 :liaTableInfo="pageInfo.liaTableInfo" v-if="initStatus" :year="pageInfo.year"
					:versions="0" />
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>15</span>
				</div>
			</div>
			<!--第十五页-->
			<div class="page page-fifteen" id="page-fifteen">
				<!-- 页面内容-->
				<!-- 附件1-->
				<LibTableComponent2 :liaTableInfo="pageInfo.liaTableInfo" v-if="initStatus" :year="pageInfo.year"
					:versions="0" />
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>16</span>
				</div>
			</div>
			<!--第十六页-->
			<div class="page page-sixteen" id="page-sixteen">
				<!-- 页面内容-->
				<ProfitTableComponent1 :profitTableInfo="pageInfo.profitTableInfo" v-if="initStatus" :year="pageInfo.year"
					:versions="0" />
				<!-- 附件2-->
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>17</span>
				</div>
			</div>
			<!--第十七页-->
			<div class="page page-sixteen_" id="page-sixteen_">
				<!-- 页面内容-->
				<!-- 附件2-->
				<ProfitTableComponent2 :profitTableInfo="pageInfo.profitTableInfo" v-if="initStatus" :year="pageInfo.year"
					:financeRatio="pageInfo.financeRatio" :versions="0" />
				<!--页脚-->
				<div class="pageNum">
					<p>///</p><span>18</span>
				</div>
			</div>
			<!--第十八页-->
			<div class="page page-seventeen" id="page-seventeen">
				<!-- 页面内容-->
				<div class="page-content">

					<!-- 附件2-->
					<div class="enclosure-title">
						<span>附件3</span>
						<p>计算公式</p>
					</div>
					<div class="formula-container">
						<h3>1、估值方法公式</h3>
						<ul>
							<li>
								<span>◆</span>
								<div class="formula-tit">
									<h4>FCFF计算公式:</h4>
									<p>FCFF=净利润+利息费用+非现金支出-营运资本支出-资本性支出。系统通过该公式计算企业无杠杆自由现金流。</p>
								</div>
							</li>
							<li>
								<span>◆</span>
								<div class="formula-tit">
									<h4>WACC(加权平均资本成本)计算公式:</h4>
									<p>
										WACC=(债务/总资本)×债务成本×(1-企业所得税税率)+(资产净值/总资本)×股权成本。系统深入研究A股、新三板等板块市场风险、行业风险，整理统计出行业资本结构、股权成本、债权成本等，系统将根据企业所填信息，自动选取对应行业WACC数值。
									</p>
								</div>
							</li>
							<li>
								<span>◆</span>
								<div class="formula-tit">
									<h4>GGM(Gorden Growth Model)</h4>
									<h4><img src="../../assets/img/report/formulary.png" /></h4>
									<p>系统根据该公式计算该模型下的企业终值，并加以折现，计算企业现值。</p>
								</div>
							</li>
							<li>
								<span>◆</span>
								<div class="formula-tit">
									<h4>市盈率法计算公式:</h4>
									<p>企业价值=行业市盈率中值*企业净利润（预测）。系统将企业按照证监会发布的最新上市公司行业分类指引进行分类，行业市盈率中值以证监会公开的上市公司行业市盈率中值为准。
									</p>
								</div>
							</li>
							<li>
								<span>◆</span>
								<div class="formula-tit">
									<h4>市净率法计算公式:</h4>
									<p>企业价值=行业市净率中值*企业净资产（预测）。系统将企业按照证监会发布的最新上市公司行业分类指引进行分类，行业市净率中值以证监会公开的上市公司行业市净率中值为准。
									</p>
								</div>
							</li>
							<li>
								<span>◆</span>
								<div class="formula-tit">
									<h4>市销率法计算公式:</h4>
									<p>企业价值=行业市销率中值*企业营业收入（预测）。系统将企业按照证监会发布的最新上市公司行业分类指引进行分类，行业市销率中值以证监会公开的上市公司行业市销率中值为准。
									</p>
								</div>
							</li>
							<li>
								<span>◆</span>
								<div class="formula-tit">
									<h4>市现率法计算公式：</h4>
									<p>企业价值=行业市现率中值*企业EBITDA（预测）。系统将企业按照证监会发布的最新上市公司行业分类指引进行分类，行业市现率中值以证监会公开的上市公司行业市现率中值为准。
									</p>
								</div>
							</li>
						</ul>
					</div>
					<div class="formula-container">
						<h3>2、财务指标公式</h3>
						<ul>
							<li>
								<span>◆</span>
								<p>资产负债率=负债合计/资产总计×100%</p>
							</li>
							<li>
								<span>◆</span>
								<p>流动比率=流动资产合计/流动负债合计</p>
							</li>
							<li>
								<span>◆</span>
								<p>EBIT利息保障倍数=EBIT/利息费用</p>
							</li>
							<li>
								<span>◆</span>
								<p>毛利率=（营业收入-营业成本）/营业收入×100%</p>
							</li>
							<li>
								<span>◆</span>
								<p>净利润率=净利润/营业收入×100%</p>
							</li>
							<li>
								<span>◆</span>
								<p>主营业务收入增长率=（本年度营业收入-上年度营业收入）/上年度营业收入×100%</p>
							</li>
							<li>
								<span>◆</span>
								<p>净资产收益率=净利润×2/（本年度期初净资产+本年度期末净资产）×100%</p>
							</li>
							<li>
								<span>◆</span>
								<p>应收账款周转率=营业收入/平均应收账款</p>
							</li>
							<li>
								<span>◆</span>
								<p>存货周转率=营业成本/平均存货</p>
							</li>
						</ul>
					</div>
				</div>

				<!-- 页脚-->
				<div class="pageNum">
					<p>///</p><span class="isAnd_">19</span>
				</div>
			</div>
			<!--第十九页-->
			<div class="page page-eightteen" id="page-eightteen">

				<!-- 页面内容-->
				<div class="page-content page-last">

					<img src="../../assets/img/report/map@2x.png" alt="线上估值-心流" />

					<div class="flow-statement">
						<img src="../../assets/img/logo-08.svg" alt="线上估值-心流" />
						<ul>
							<li>
								<div class="statement-tit">
									<span class="xian"></span>
									<img src="../../assets/img/report/statement-01@2x.png" alt="专业估值报告的估值声明-心流" />
									<p>估值声明</p>
									<img src="../../assets/img/report/statement-01@2x.png" alt="专业估值报告的估值声明-心流" />
									<span class="xian"></span>
								</div>
								<div class="statement-main">
									本报告由系统自动生成。该系统依据企业提供的财务数据和软性指标，结合具备可靠性水平的数据库，根据通用的行业业绩及财务预测逻辑和规则，对企业估值所需要的必要参数进行预测和估算，企业提供资料的完整度和准确性对最终估值结果有重要影响。请本报告使用者知悉。
								</div>
							</li>
							<li>
								<div class="statement-tit">
									<span class="xian"></span>
									<img src="../../assets/img/report/statement-01@2x.png" alt="专业估值报告的免责声明-心流" />
									<p>免责声明</p>
									<img src="../../assets/img/report/statement-01@2x.png" alt="专业估值报告的免责声明-心流" />
									<span class="xian"></span>
								</div>
								<div class="statement-main">
									系统所采用的数据信息来源于企业自行提供的资料和公开渠道可获取的行业信息和数据，并基于前述资料属实、合法的假设出具本报告，网站亦不对前述资料的准确性、完整性负有实质审核的义务，本报告仅作为对企业整体价值衡量的参考，非并购、转让等交易的直接证据，网站及其运营方不承担因信赖本报告信息而采取行动所导致的任何损失及一切法律责任。
								</div>
							</li>
							<li>
								<div class="statement-tit">
									<span class="xian"></span>
									<img src="../../assets/img/report/statement-01@2x.png" alt="心流慧估的介绍-心流" />
									<p>心流慧估</p>
									<img src="../../assets/img/report/statement-01@2x.png" alt="心流慧估的介绍-心流" />
									<span class="xian"></span>
								</div>
								<div class="statement-main lastStatement-main">
									<p>
										北京心流慧估科技有限公司是国内外领先的投行AIGC公司，专注于用AI技术为中国众多中小企业提供“估值+组合式金融服务”，通过自主研发的曼殊AI（Manju
										AI）系列软件产品，从发现、培育优质股权，再到资本对接，引金融之水精确“滴灌”，低成本、高效率地为中小企业走进资本市场提供定制化解决方案
									</p>
									<p>
										心流慧估运用人工智能技术和多种统计分析方法，将国际通用估值技术结合心流慧估独创软性指标体系，搭建出完善的股权估值模型和算法模型。采用AIGC技术将估值模型、对标企业、指标权重、语句分析自动匹配并实时在线生成完整专业报告，不仅能科学、客观、公允地评估企业价值，还能帮助企业更好地进行融资规划、财务系统建设、企业管理规范，提高企业决策和融资效率。
									</p>
									<div>
										<img src="../../assets/img/wechartCode.jpg" alt="">
										<ul>
											<li><span>网址</span>：www.flcccc.com</li>
											<li><span>客服电话</span>：17610076778</li>
											<li><span>微信号</span>：flcccc888</li>
											<li><span>地址</span>：北京市朝阳区酒仙桥路甲12号1号楼15层1505-1室</li>
										</ul>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>

				<!-- 页脚-->
				<div class="pageNum">
					<p>///</p><span class="isAnd_ andOne">20</span>
				</div>
			</div>
		</div>
		<div id="loading" style="display: none;">
			<div class='base'>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
				<div class='cube'></div>
			</div>
		</div>
	</div>
</template>
<script>
	import qs from 'qs'
	import common from '@/components/reportPage/common'
	import '../../assets/css/loading.css'
	import formatCurrency from '../../assets/js/numberToCurrency'
	import PdfOnload from '../../utils/pdf'
	import Draw2DPieCanvas from '../../common/echarts/draw2DPieCanvas.vue'
	import DrawRadarCanvas2v from '../../common/echarts/drawRadarCanvas2v.vue'
	import DrawBarFinancialAnalysisRelativeX from '../../common/echarts/drawBarFinancialAnalysisRelativeX.vue'
	import DrawBarFinancialAnalysisRelativeY from '../../common/echarts/drawBarFinancialAnalysisRelativeY.vue'
	import DrawPileBar from '../../common/echarts/drawPileBar.vue'
	import DrawBarLine from '../../common/echarts/drawBarLine.vue'
	import DrawGaugePic from '../../common/echarts/drawGaugePic.vue'
	import DrawBarProfitForecast from '../../common/echarts/drawBarProfitForecast.vue'
	import DrawBarFinancialAnalysisY from '../../common/echarts/drawBarFinancialAnalysisY.vue'
	import DrawBarFinancialAnalysisX from '../../common/echarts/drawBarFinancialAnalysisX.vue'
	import DupontAnalyze from '../../common/reportComponent/dupontAnalyze.vue'
	import LibTableComponent1 from '../../common/reportComponent/liaTableComponent1.vue'
	import LibTableComponent2 from '../../common/reportComponent/liaTableComponent2.vue'
	import ProfitTableComponent1 from '../../common/reportComponent/profitTableComponent1.vue'
	import ProfitTableComponent2 from '../../common/reportComponent/profitTableComponent2.vue'
	export default ({
		name: "valuationReport",
		mixins: [common],
		components: {
			Draw2DPieCanvas,
			DrawRadarCanvas2v,
			DrawBarFinancialAnalysisRelativeX,
			DrawBarFinancialAnalysisRelativeY,
			DrawPileBar,
			DrawBarLine,
			DrawGaugePic,
			DrawBarProfitForecast,
			DrawBarFinancialAnalysisY,
			DrawBarFinancialAnalysisX,
			DupontAnalyze,
			LibTableComponent1,
			LibTableComponent2,
			ProfitTableComponent1,
			ProfitTableComponent2
		},
		data() {
			return {
				reportId: this.$route.query.reportId ? this.$route.query.reportId : "",
				pageInfo: {
					isTestAccount: null, //0:无水印可下载，1：有水印不可下载
					baseInfo: {
						companyName: "",
						establishDate: ""
					},
					year: [],
					absoluteValuation: {
						ggm: ""
					}
				},
				// 相对估值
				relativeText: "", // 图三
				// 
				nowYear: "",
				// 
				scoreText: "",
				// 专业化语句
				industryStatements: [],
				// 精细化语句
				competitivePowerStatements: [],
				// 特色化语句
				teamManageStatements: [],
				// 新颖化语句
				technologyStatements: [],
				// 资产负债率
				liabilitiesRatioStatement: "",
				liabilitiesRatioStatementArr: [],
				liabilitiesRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// 流动比率
				flowRationStatement: "",
				flowRationStatementArr: [],
				flowRationStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// EBIT利息保障倍数
				ebitMultipleStatement: "",
				ebitMultipleStatementArr: [],
				ebitMultipleStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				ebitMultipleGradeStatus: 1,
				// 毛利率
				grossProfitRatioStatement: "",
				grossProfitRatioStatementArr: [],
				grossProfitRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// 净利润率
				netProfiltRatioStatement: "",
				netProfiltRatioStatementArr: [],
				netProfiltRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// 主营业务收入增长率
				mainIncomeRatioStatement: "",
				mainIncomeRatioStatementArr: [],
				mainIncomeRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// 净资产收益率
				netAssetRatioStatement: "",
				netAssetRatioStatementArr: [],
				netAssetRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				// 应收账款周转率
				receivablesTurnoverRatioStatement: "",
				receivablesTurnoverRatioStatementArr: [],
				receivablesTurnoverRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				receivablesTurnoverRatioGradeStatus: 1,
				// 存货周转率
				inventoryTurnoverRatioStatement: "",
				inventoryTurnoverRatioStatementArr: [],
				inventoryTurnoverRatioStatementSrc: require("../../assets/img/report/dianchi-100.svg"),
				inventoryTurnoverRatioGradeStatus: 1,
				// 
				pdfArr: [],
				pdfIndex: 0,
				projectName: ''
			}
		},
		mounted() {
			this.projectName = this.domain.valuationProject
			if (this.reportId) {
				this.initPage();
			}
		},
		methods: {
			initPage: function() {
				var that = this;
				var queryObj = {
					reportId: that.reportId
				}
				that.$post(this.projectName + "valuationReport/qryValuationReport", qs.stringify(queryObj)).then((
					res) => {
					var data = res.data;
					if (data.code == 200 && data.content) {
						console.log(data.content);
						that.pageInfo = data.content;
						// 股权
						var holderArr = [];
						for (var i in JSON.parse(that.pageInfo.baseInfo.holderRation)) {
							holderArr.push({
								name: i,
								y: JSON.parse(that.pageInfo.baseInfo.holderRation)[i],
							})
						};
						that.pageInfo.baseInfo.holderRation = holderArr;
						// 心流图谱
						that.pageInfo.question = JSON.parse(that.pageInfo.question);
						// 财务分析
						that.pageInfo.financial = JSON.parse(that.pageInfo.financial);
						// 相对估值
						if (that.pageInfo.relativeValuation) {
							that.pageInfo.relativeValuation = JSON.parse(that.pageInfo.relativeValuation);
							var relativeValueKeys = "";
							var relativeValues = "";
							for (var i = 0; i < that.pageInfo.relativeValuation.valuationValue
								.length; i++) {
								if (i != that.pageInfo.relativeValuation.valuationValue.length - 1) {
									relativeValueKeys += that.pageInfo.relativeValuation.valuationValue[i]
										.name + "法、";
									relativeValues += formatCurrency.formateNum(that.pageInfo
										.relativeValuation.valuationValue[i].value, 2) + "万元、";
								} else {
									relativeValues += formatCurrency.formateNum(that.pageInfo
											.relativeValuation.valuationValue[i].value, 2) +
										"万元，经调整后，最终相对估值企业估值为" + that.valueFormate(that.pageInfo
											.relativeValuationValue);
									relativeValueKeys += that.pageInfo.relativeValuation.valuationValue[i]
										.name + "法对企业的估值结果分别为：";
								}
							}
							that.relativeText = relativeValueKeys + relativeValues;
						};
						// 绝对估值
						if (that.pageInfo.absoluteValuation) {
							that.pageInfo.absoluteValuation = JSON.parse(that.pageInfo.absoluteValuation);
							that.nowYear = parseInt(that.pageInfo.createTime.split("-")[0]);
						}
						// 分值说明
						var scoreText = "";
						for (var i = 0; i < that.pageInfo.question.length; i++) {
							if (i != that.pageInfo.question.length - 1) {
								scoreText += that.pageInfo.question[i].name + that.pageInfo.question[i]
									.value + '分、'
							} else {
								scoreText += that.pageInfo.question[i].name + that.pageInfo.question[i]
									.value + '分。'
							}
						}
						that.scoreText =
							'估值图谱是以产品为核心，根据影响企业估值的内外因素形成的企业评价体系，具体包括专业化（主营业务专业专注）、精细化（经营管理精细高效）、特色化（产品服务独具特色）和新颖化（创新能力成果显著）四方面。四项指标分别对应不同的问题，根据企业的答题情况结合相应的打分规则最终得出的四项指标的得分，如图谱所示：' +
							scoreText;
						// 专业化 industry
						if (that.pageInfo.industry) {
							that.pageInfo.industry = JSON.parse(that.pageInfo.industry);
							for (var i = 0; i < that.pageInfo.industry.length; i++) {
								that.industryStatements.push(that.pageInfo.industry[i].description);
							}
						};
						// 精细化 competitivePower
						if (that.pageInfo.competitivePower) {
							that.pageInfo.competitivePower = JSON.parse(that.pageInfo.competitivePower);
							for (var i = 0; i < that.pageInfo.competitivePower.length; i++) {
								that.competitivePowerStatements.push(that.pageInfo.competitivePower[i]
									.description);
							}
						};
						// 特色化 teamManage
						if (that.pageInfo.teamManage) {
							that.pageInfo.teamManage = JSON.parse(that.pageInfo.teamManage);
							for (var i = 0; i < that.pageInfo.teamManage.length; i++) {
								that.teamManageStatements.push(that.pageInfo.teamManage[i].description);
							}
						};
						// 新颖化 technology
						if (that.pageInfo.technology) {
							that.pageInfo.technology = JSON.parse(that.pageInfo.technology);
							for (var i = 0; i < that.pageInfo.technology.length; i++) {
								that.technologyStatements.push(that.pageInfo.technology[i].description);
							}
						};
						// 营业收入预测
						if (that.pageInfo.incomeForecast) {
							that.pageInfo.incomeForecast = JSON.parse(that.pageInfo.incomeForecast);
						};
						// 净利润预测
						if (that.pageInfo.netProfitForecast) {
							that.pageInfo.netProfitForecast = JSON.parse(that.pageInfo.netProfitForecast);
						};
						// 资产负债率
						if (that.pageInfo.liabilitiesRatio) {
							that.pageInfo.liabilitiesRatio = JSON.parse(that.pageInfo.liabilitiesRatio);
							that.pageInfo.liabilitiesRatio.name.push("");
							// 语句
							var liabilitiesRatioAssetArr = that.pageInfo.liabilitiesRatio.description
								.split("|");
							that.liabilitiesRatioStatement = liabilitiesRatioAssetArr[0];
							that.liabilitiesRatioStatementArr = liabilitiesRatioAssetArr.splice(1);
							that.liabilitiesRatioStatementSrc = that.srcFormate(that.pageInfo
								.liabilitiesRatio.batteryLevel);
						}
						// 流动比率
						if (that.pageInfo.flowRation) {
							that.pageInfo.flowRation = JSON.parse(that.pageInfo.flowRation);
							that.pageInfo.flowRation.name.push("");
							// 语句
							var flowRationAssetArr = that.pageInfo.flowRation.description.split("|");
							that.flowRationStatement = flowRationAssetArr[0];
							that.flowRationStatementArr = flowRationAssetArr.splice(1);
							that.flowRationStatementSrc = that.srcFormate(that.pageInfo.flowRation
								.batteryLevel);
						}
						// EBIT利息保障倍数
						if (that.pageInfo.ebitMultiple) {
							that.pageInfo.ebitMultiple = JSON.parse(that.pageInfo.ebitMultiple);
							that.pageInfo.ebitMultiple.name.push("");
							var interestCost = JSON.parse(that.pageInfo.profitTableInfo).interestCost;
							if (interestCost[1] == "") {
								that.ebitMultipleGradeStatus = 0;
							} else {
								that.ebitMultipleGradeStatus = 1;
							}
							// 语句
							var ebitMultipleAssetArr = that.pageInfo.ebitMultiple.description.split("|");
							that.ebitMultipleStatement = ebitMultipleAssetArr[0];
							that.ebitMultipleStatementArr = ebitMultipleAssetArr.splice(1);
							that.ebitMultipleStatementSrc = that.srcFormate(that.pageInfo.ebitMultiple
								.batteryLevel);
						}
						// 毛利率
						if (that.pageInfo.grossProfitRatio) {
							that.pageInfo.grossProfitRatio = JSON.parse(that.pageInfo.grossProfitRatio);
							that.pageInfo.grossProfitRatio.name.push("");
							// 语句
							var grossProfitRatioAssetArr = that.pageInfo.grossProfitRatio.description
								.split("|");
							that.grossProfitRatioStatement = grossProfitRatioAssetArr[0];
							that.grossProfitRatioStatementArr = grossProfitRatioAssetArr.splice(1);
							that.grossProfitRatioStatementSrc = that.srcFormate(that.pageInfo
								.grossProfitRatio.batteryLevel);
						}
						// 净利润率
						if (that.pageInfo.netProfiltRatio) {
							that.pageInfo.netProfiltRatio = JSON.parse(that.pageInfo.netProfiltRatio);
							that.pageInfo.netProfiltRatio.name.push("");
							// 语句
							var netProfiltRatioAssetArr = that.pageInfo.netProfiltRatio.description.split(
								"|");
							that.netProfiltRatioStatement = netProfiltRatioAssetArr[0];
							that.netProfiltRatioStatementArr = netProfiltRatioAssetArr.splice(1);
							that.netProfiltRatioStatementSrc = that.srcFormate(that.pageInfo
								.netProfiltRatio.batteryLevel);
						}
						// 主营业务收入增长率
						if (that.pageInfo.mainIncomeRatio) {
							that.pageInfo.mainIncomeRatio = JSON.parse(that.pageInfo.mainIncomeRatio);
							that.pageInfo.mainIncomeRatio.name.push("");
							// 语句
							var mainIncomeRatioAssetArr = that.pageInfo.mainIncomeRatio.description.split(
								"|");
							that.mainIncomeRatioStatement = mainIncomeRatioAssetArr[0];
							that.mainIncomeRatioStatementArr = mainIncomeRatioAssetArr.splice(1);
							that.mainIncomeRatioStatementSrc = that.srcFormate(that.pageInfo
								.mainIncomeRatio.batteryLevel);
						}
						// 净资产收益率
						if (that.pageInfo.netAssetRatio) {
							that.pageInfo.netAssetRatio = JSON.parse(that.pageInfo.netAssetRatio);
							that.pageInfo.netAssetRatio.name.push("");
							// 语句
							var netAssetRatioAssetArr = that.pageInfo.netAssetRatio.description.split("|");
							that.netAssetRatioStatement = netAssetRatioAssetArr[0];
							that.netAssetRatioStatementArr = netAssetRatioAssetArr.splice(1);
							that.netAssetRatioStatementSrc = that.srcFormate(that.pageInfo.netAssetRatio
								.batteryLevel);
						}
						// 应收账款周转率
						if (that.pageInfo.receivablesTurnoverRatio) {
							that.pageInfo.receivablesTurnoverRatio = JSON.parse(that.pageInfo
								.receivablesTurnoverRatio);
							that.pageInfo.receivablesTurnoverRatio.name.push("");
							var receivables = JSON.parse(that.pageInfo.liaTableInfo).receivables;
							if (receivables[1] == "") {
								that.receivablesTurnoverRatioGradeStatus = 0;
							} else {
								that.receivablesTurnoverRatioGradeStatus = 1;
							}
							// 语句
							var receivablesTurnoverRatioAssetArr = that.pageInfo.receivablesTurnoverRatio
								.description.split("|");
							that.receivablesTurnoverRatioStatement = receivablesTurnoverRatioAssetArr[0];
							that.receivablesTurnoverRatioStatementArr = receivablesTurnoverRatioAssetArr
								.splice(1);
							that.receivablesTurnoverRatioStatementSrc = that.srcFormate(that.pageInfo
								.receivablesTurnoverRatio.batteryLevel);
						}
						// 存货周转率
						if (that.pageInfo.inventoryTurnoverRatio) {
							that.pageInfo.inventoryTurnoverRatio = JSON.parse(that.pageInfo
								.inventoryTurnoverRatio);
							that.pageInfo.inventoryTurnoverRatio.name.push("");
							var inventory = JSON.parse(that.pageInfo.liaTableInfo).inventory
							if (inventory[1] == "") {
								that.inventoryTurnoverRatioGradeStatus = 0;
							} else {
								that.inventoryTurnoverRatioGradeStatus = 1;
							}
							// 语句
							var inventoryTurnoverRatioAssetArr = that.pageInfo.inventoryTurnoverRatio
								.description.split("|");
							that.inventoryTurnoverRatioStatement = inventoryTurnoverRatioAssetArr[0];
							that.inventoryTurnoverRatioStatementArr = inventoryTurnoverRatioAssetArr
								.splice(1);
							that.inventoryTurnoverRatioStatementSrc = that.srcFormate(that.pageInfo
								.inventoryTurnoverRatio.batteryLevel);
						};
						// 杜邦分析
						if (that.pageInfo.dubangAnalysis) {
							that.pageInfo.dubangAnalysis = JSON.parse(that.pageInfo.dubangAnalysis);
						}
						// 财务分析
						that.pageInfo.financial[0].color = "#0478fc";
						that.pageInfo.financial[1].color = "#FD865A";
						that.pageInfo.financial[2].color = "#775BFF";
						that.pageInfo.financial[3].color = "#00BF8C";
						// 资产负债表
						that.pageInfo.liaTableInfo = JSON.parse(that.pageInfo.liaTableInfo);
						// 利润表
						that.pageInfo.profitTableInfo = JSON.parse(that.pageInfo.profitTableInfo);
						// 财务比率表
						that.pageInfo.financeRatio = JSON.parse(that.pageInfo.financeRatio);
					}
					
					this.$nextTick(() => {
						this.addPageWatermark('.page',this.pageInfo.isTestAccount)
					})
					
					
				})
			},
			// 日期格式化
			dateFormate: function(date) {
				if (date) {
					var dataStr = date.split("-");
					var newDataStr = dataStr[0] + "年" + dataStr[1] + "月" + dataStr[2] + "日";
					return newDataStr;
				}
			},
			// 估值结果格式化
			resultFormate: function(min, max) {
				var val = '';
				if (min) {
					return this.method.valuationResultConversion(min, max)
				} else {
					return '无数据'
				}
			},
			// 
			valueFormate: function(val) {
				if (val) {
					return this.method.resultConversion(val)
				} else {
					return '无数据'
				}
			},
			/*页脚日期格式转换*/
			replaceAll: function(val) {
				if (val) {
					return val.replace(new RegExp("-", "gm"), ".");
				}

			},
			srcFormate: function(val) {
				if (val == "20%") {
					return require("../../assets/img/report/dianchi-20.svg")
				} else if (val == "40%") {
					return require("../../assets/img/report/dianchi-40.svg")
				} else if (val == "60%") {
					return require("../../assets/img/report/dianchi-60.svg")
				} else if (val == "80%") {
					return require("../../assets/img/report/dianchi-80.svg")
				} else if (val == "100%") {
					return require("../../assets/img/report/dianchi-100.svg")
				}
			},
			pdf: function() {
				document.getElementById("loading").style.display = "block";
				var title = this.pageInfo.baseInfo.companyName + '价值量化报告.pdf'
				var pageArr = document.getElementsByClassName("page");
				for (var i = 0; i < pageArr.length; i++) {
					this.pdfArr.push(pageArr[i].id)
				}
				PdfOnload.getPdf(this.pdfArr, this.pdfIndex, title);
			}
		},
		computed: {
			initStatus: function() {
				if (this.pageInfo.baseInfo.companyName) {
					return true;
				} else {
					return false;
				}
			}
		}
	})
</script>
<style scoped lang="less">
	.tools {
		width: 1160px;
		height: 60px;
		margin: 0 auto;
		padding-top: 10px;
		box-sizing: border-box;
	}

	.tools button {
		float: right;
		width: 120px;
		padding: 8px;
		border: 1px #aaa solid;
		border-radius: 4px;
		outline: none;
		font-size: 16px;
		transition: background-color 0.3s;
		background: #B1781E;
		color: #fff;
		margin-right: 20px;
		cursor: pointer;
	}

	/*页眉*/
	.page-header {
		margin: 30px 0 38px 0;
		overflow: hidden;
	}

	.page-header>div {
		float: right;
	}

	.page-header .page-header-tip {
		width: 20px;
		height: 40px;
		background: url(../../assets/img/report/page-header.png) no-repeat;
		background-size: 100% 100%;
		position: relative;
		right: -1px;
		z-index: 0;
	}

	.page-header .page-header-company {
		height: 40px;
		padding: 0 30px;
		font-size: 18px;
		line-height: 40px;
		background: #0478fc;
		color: #FFF;
		margin-right: 77px;
	}


	/* 页脚*/

	.page-footer {
		position: absolute;
		bottom: 0;
		height: 28px;
		width: 1010px;
		margin-left: 76px;
		margin-bottom: 37px;
	}

	.page-footer>.page-footer-left {
		float: left;
		font-size: 14px;
		color: #282828;
		height: 28px;
		line-height: 28px;
	}

	.page-footer>.page-footer-left>span:nth-child(2) {
		margin: 0 5px;
	}

	.page-footer>.page-footer-left>span:last-of-type {
		color: #0D0D0D;
	}

	.page-footer>.page-footer-right {
		float: right;
		font-size: 0;
	}

	.page-footer>.page-footer-right>.date {
		height: 26px;
		border-bottom: 2px solid #0478fc;
		line-height: 28px;
		padding: 0 5px;
		font-size: 14px;
		color: #282828;
	}

	.page-footer>.page-footer-right>div {
		display: inline-block;
	}

	.page-footer>.page-footer-right .page-num {
		width: 28px;
		height: 28px;
		background: url(../../assets/img/report/page-num@2x.png) no-repeat;
		text-align: center;
		line-height: 28px;
		font-size: 14px;
		color: #fff;
	}


	/*第一页*/

	.page-one>img {
		width: 386px;
		height: 130px;
		margin: 58px 0 96px 54px;
	}

	.page-main {
		width: 1240px;
		height: 1428px;
		background: url(../../assets/img/report/pro-page-onePic.png) 176px 0px no-repeat;
		background-size: 90%;
		overflow: hidden;
		text-align: center;
	}

	.page-main>p {
		margin-top: 180px;
		text-align: center;
		font-size: 47px;
		color: #0478fc;
		letter-spacing: 2px;
		line-height: 1;
	}

	.page-main>h5 {
		font-size: 143px;
		font-weight: 400;
		color: #0478fc;
		letter-spacing: 30px;
		line-height: 100%;
		margin-top: 53px;
	}

	.page-main>h6 {
		font-size: 38px;
		font-weight: 400;
		color: #0478fc;
		line-height: 100%;
		margin-top: 41px;
	}

	.page-one-footer {
		position: absolute;
		bottom: 52px;
		height: 21px;
		width: 1048px;
		margin: 0 34px;
	}

	.page-one-footer>p {
		float: right;
		margin-right: 193px;
		font-size: 18px;
		color: #282828;
		line-height: 1;
	}

	.page-one-footer>p>span:nth-child(2) {
		margin: 0 5px;
	}

	.page-one-footer>p>span:last-of-type {
		color: #0D0D0D;
	}

	.page-one-footer>div.date {
		float: right;
		font-size: 18px;
		line-height: 1;
	}

	#content,
	#content1 {
		width: 1240px;
		height: auto;
		margin: 0 auto;
	}

	.report-page {
		width: 1240px;
		margin: 0 auto;
	}

	.page {
		width: 100%;
		height: 1755px;
		background-color: #fff;
		overflow: hidden;
		position: relative;
		border-bottom: 1px solid #cccccc;
	}

	.reportNumber {
		position: absolute;
		top: 6.5%;
		right: 45px;
		font-size: 18px;
	}

	/*目录页*/

	.catalogue-img-container {
		position: absolute;
		top: 110px;
		left: 24px;
		height: 277px;
	}

	.catalogue-img-container>img {
		display: block;
		height: 100%;
	}

	.littleMask {
		position: absolute;
		width: 160px;
		height: 1150px;
		background: #fff;
		right: 0;
		top: 300px;
		z-index: 10;
	}

	.page-catalogue>ul {
		margin-top: 344px;
		margin-left: 118px;
		margin-right: 118px;
	}

	.page-catalogue>ul>li {
		margin-bottom: 20px;
	}

	.page-catalogue>ul>li>div {
		position: relative;
	}

	.page-catalogue>ul>li>div>span {
		color: #000;
		line-height: 36px;
	}

	.page-catalogue>ul>li>div>span:nth-of-type(1) {
		margin-right: 130px;
		font-size: 40px;
	}

	.page-catalogue>ul>li>div>span:nth-of-type(2) {
		font-size: 18px;
		color: #282828;
	}

	.page-catalogue>ul>li>div>span:nth-of-type(3) {
		position: absolute;
		width: 70%;
		height: 1px;
		top: 18px;
		margin-left: 30px;
		background: url(../../assets/img/report/catalogue_.png) left center repeat;
	}

	.page-catalogue>ul>li>div>span:nth-of-type(4) {
		line-height: 34px;
		position: absolute;
		right: 0;
		z-index: 100;
		font-size: 18px;
	}

	.page-catalogue>ul>li>div.title>span {
		color: #000;
		margin-bottom: 20px;
	}

	.page-catalogue>ul>li>div.title>span:nth-of-type(2) {
		font-size: 24px;
	}

	.dot-line {
		position: absolute;
		top: 353px;
		left: 214px;
	}

	.dot-line>span {
		display: block;
		position: relative;
		width: 20px;
		height: 20px;
		background: #0478fc;
		border-radius: 10px;
		z-index: 2;
	}

	.dot-line>span:nth-of-type(2) {
		margin-top: 58px;
	}

	.dot-line>span:nth-of-type(3) {
		margin-top: 126px;
	}

	.dot-line>span:nth-of-type(4) {
		margin-top: 238px;
	}

	.dot-line>span:nth-of-type(5) {
		margin-top: 54px;
	}

	.dot-line>span:nth-of-type(6) {
		margin-top: 128px;
	}

	.dot-line>span:nth-of-type(7) {
		margin-top: 238px;
	}

	.dot-line>span:nth-of-type(8) {
		margin-top: 56px;
	}

	.dot-line>span:nth-of-type(9) {
		margin-top: 56px;
	}

	.dot-line>div {
		position: absolute;
		top: 0;
		left: 9px;
		width: 2px;
		height: 1120px;
		background: #a9a9a9;
	}


	/*第二页*/

	.page-text>.page-text-header {
		border-bottom: 2px solid #666666;
		height: 38px;
	}

	.page-text-tit {
		overflow: hidden;
		background: #0478fc;
		display: inline-block;
		float: left;
	}

	.page-text-tit>img {
		width: 26px;
		height: 26px;
		margin: 7px 10px 0 10px;
		float: left;
	}

	.page-text-tit>span {
		height: 40px;
		margin-right: 10px;
		line-height: 40px;
		display: block;
		float: left;
		font-size: 20px;
		color: #fff;
	}

	.page-text>.page-text-header>img {
		width: 10px;
		height: 40px;
		float: left;
		margin-left: -1px;
	}

	.page-text-content {
		padding: 20px 0;
	}

	.page-two .page-text-content {
		padding: 20px 10px;
		font-size: 16px;
	}

	.result-container {
		overflow: hidden;
	}

	.result-container>img {
		width: 86px;
		margin-right: 27px;
		float: left;
	}

	.result-container>p.result-num {
		margin: 16px 0 10px 0;
		font-size: 30px;
		line-height: 1;
		color: #0478fc;
	}

	.result-container>p:last-of-type {
		font-size: 16px;
		line-height: 1;
		margin-bottom: 11px;
		color: #666;
	}

	.result-desc {
		font-size: 16px;
		color: #282828;
		line-height: 30px;
		text-align: justify;
		text-indent: 2em;
		/* margin-bottom: 12px; */
		margin-left: 15px;
	}

	.page-text-content>.page-text-left {
		float: left;
		width: 464px;
		margin: 0 60px 20px 0;
	}

	.page-text-content>.page-text-right {
		float: left;
		width: 448px;
	}

	.page-text-content .row {
		font-size: 16px;
		color: #282828;
		overflow: hidden;
		line-height: 1.8;
	}

	.page-text-content .row>label {
		float: left;
	}

	.page-text-content .row>span {
		float: left;
	}

	.page-text-content>.page-text-left>.row>span {
		max-width: 384px;
	}

	.page-text-content>.page-text-right>.row>span {
		max-width: 358px;
	}

	.clear {
		clear: both;
		/* margin-bottom: 20px; */
	}

	p.result-num>span {
		margin-right: 5px;
	}

	p.result-num>span.line {
		margin: 0 5px;
	}

	.page-one-bottom {
		overflow: hidden;
	}

	.page-one-left {
		width: 474px;
		margin-right: 59px;
		float: left;
	}

	.page-one-right {
		width: 470px;
		float: left;
	}

	.pageOne-canvas01 {
		height: 400px;
	}

	.pageOne-canvas02,
	.pageOne-canvas03 {
		height: 273px;
	}


	/*第三页*/

	.relavtive-container {
		height: 638px;
	}

	.relavtive-left {
		width: 425px;
		float: left;
		height: 638px;
	}

	.relavtive-mid-container {
		overflow: hidden;
		width: 156px;
		float: left;
	}

	.relavtive-mid {
		background: url(../../assets/img/report/dottedPic@2x.png)no-repeat;
		margin-top: 104px;
		width: 30px;
		height: 467px;
		/* border: 1px dotted #0478fc; */
		/* border-left: 0; */
		float: left;
	}

	.amount {
		margin: 310px 0px 0 59px;
		width: 65px;
		height: 46px;
		display: block;
		float: left;
		background: url(../../assets/img/report/equal@2x.png) no-repeat;
	}

	.relavtive-right {
		width: 410px;
		height: 385px;
		margin-top: 145px;
		float: left;
	}

	.relavtive-right>p {
		font-size: 16px;
		color: #282828;
		line-height: 1.5;
		text-align: center;
	}

	.relavtive-right>.relavtive-three {
		height: 360px;
	}

	.relavtive-one,
	.relavtive-two {
		margin-top: 40px;
		height: 250px;
		width: 100%;
	}

	.relavtive-one>div.div-canvas,
	.relavtive-two>div.div-canvas,
	.relavtive-three>div.div-canvas {
		height: 100%;
	}

	.relative-weight {
		height: 17px;
		margin-bottom: 39px;
	}

	.relative-weight>img {
		width: 13px;
		float: left;
		height: 13px;
		margin: 2px 10px 2px 0;
	}

	.relative-weight>p {
		font-size: 16px;
		line-height: 17px;
	}

	.relative-weight-container {
		height: 65px;
		margin-bottom: 49px;
	}

	.relative-result {
		width: 760px;
		height: 117px;
		margin: 0 auto;
		/* background: url(../../assets/img/report/page02/relative-result@2x.png) no-repeat; */
		border: 3px solid #0478fc;
		border-radius: 10px;
		overflow: hidden;
		margin-bottom: 48px;
	}

	.relative-result>p {
		font-size: 22px;
		line-height: 1;
		text-align: center;
		color: #686868;
		margin: 20px 0 27px;
	}

	.relative-result>span {
		font-size: 26px;
		line-height: 1;
		text-align: center;
		display: block;
		color: #0478fc;
	}

	.page-tip-container {
		width: 966px;
		padding: 30px 21px 34px 17px;
		background: #F7F7F7;
	}

	.page-tip-container>ul {
		overflow: hidden;
	}

	.page-tip-container>ul>li {
		font-size: 16px;
		line-height: 1.875;
		overflow: hidden;
	}

	.page-tip-container>ul>li>label {
		/*font-size: 16px;*/
		font-size: 18px;
		color: #282828;
		float: left;
		line-height: 1.9;
		height: 2em;
	}

	.page-tip-container>ul>li>p {
		text-align: justify;
	}

	.relative-weight-container>div {
		float: left;
		height: 100%;
	}

	.relative-weight-container>div>span {
		display: block;
		text-align: center;
		font-size: 16px;
		line-height: 2;
		color: #fff;
	}

	.relative-weight>p {
		font-size: 16px;
		line-height: 17px;
	}


	/*第四页*/


	/*绝对估值法标题*/

	.page-text-sub-header {
		width: 1008px;
		height: 38px;
		margin: 0 auto;
		margin-top: 47px;
		border-bottom: 2px solid #666666;
	}

	.page-text-tit {
		overflow: hidden;
		background: #0478fc;
		display: inline-block;
		float: left;
	}

	.page-text-tit>img {
		width: 26px;
		height: 26px;
		margin: 7px 10px 0 10px;
		float: left;
	}

	.page-text-tit>span {
		height: 40px;
		margin-right: 10px;
		line-height: 40px;
		display: block;
		float: left;
		font-size: 20px;
		color: #fff;
	}

	.page-text-header>img {
		width: 10px;
		height: 40px;
		float: left;
	}


	/*表格部分*/

	.table_area {
		width: 1008px;
		height: 525px;
		margin: 0 auto;
		margin-top: 60px;
		overflow: hidden;
	}

	.cash-flow {
		float: left;
		width: 488px;
		height: 480px;
	}

	#cashFlowTrend {
		float: right;
	}

	.center-arrow {
		width: 32px;
		height: 23px;
		padding-top: 212px;
	}

	.center-arrow>img {
		width: 100%;
	}


	/*虚线框*/

	.dashes-line {
		width: 770px;
		height: 27px;
		margin: 0 auto;
		background: url(../../assets/img/report/dottedPicX.png)no-repeat;
		background-size: cover;
		/* border: dotted 1px #97B1D2;
        border-top: none; */
	}

	.arrow-down {
		width: 133px;
		height: 130px;
		margin: 0 auto;
		margin-top: 28px;
		background: url(../../assets/img/report/profession_page04_down_arrow.png) center center no-repeat;
		background-size: 100%;
	}

	.arrow-down>p {
		font-size: 14px;
		color: rgba(255, 255, 255, 1);
		line-height: 18px;
		text-align: center;
		padding-top: 64px;
	}

	.border-area {
		width: 760px;
		height: 117px;
		margin: 0 auto;
		margin-top: 21px;
		border: 3px solid #0478fc;
		border-radius: 6px;
	}

	.border-area>p {
		font-size: 22px;
		text-align: center;
		line-height: 40px;
		color: #686868;
	}

	.border-area>p:first-child {
		margin-top: 15px;
	}

	.border-area>p:last-child {
		margin-top: 5px;
		font-size: 26px;
		color: #0478fc;
	}

	.explain-container {
		width: 952px;
		height: auto;
		margin: 0 auto;
		margin-top: 85px;
		padding: 28px;
		background: rgba(247, 247, 247, 1);
		font-size: 16px;
		color: rgba(40, 40, 40, 1);
		line-height: 30px;
		text-align: justify;
	}


	/*第五页*/

	.socre-explain-container {
		background: #f7f7f7;
		width: 1001px;
		margin: 116px auto 0;
		height: auto;
		overflow: hidden;
	}

	.socre-explain-container>p:first-of-type {
		height: 24px;
		margin: 17px 0 12px 21px;
	}

	.socre-explain-container>p:first-of-type>img {
		height: 24px;
		width: 24px;
		margin-right: 11px;
		float: left;
	}

	.socre-explain-container>p:first-of-type>span {
		height: 24px;
		float: left;
		font-size: 18px;
		color: #282827;
		line-height: 24px;
	}

	.socre-explain-container>p:last-of-type {
		width: 95%;
		margin: 0 20px 31px 24px;
		font-size: 16px;
		line-height: 1.875;
		color: #282828;
	}

	.socre-explain-container-sapn {
		display: inline;
	}

	.industryEnvirContent,
	.enterpriseContent {
		width: 1008px;
		margin: 0 auto;
	}

	div.chart {
		height: 288px;
		width: 50%;
		margin-top: -5px;
		float: left;
	}

	.describe {
		margin-top: 20px;
	}

	.describe>p {
		font-size: 16px;
		line-height: 1.875;
		text-indent: 2em;
		text-align: justify;
	}

	.describe>p:first-of-type {
		margin-bottom: 10px;
	}

	.socre-explain-container-p>span {
		display: inline;
	}


	/*第六页*/


	/*第七页*/

	.valuation-result-container {
		width: 896px;
		height: 319px;
		margin: 0 auto;
		margin-top: 136px;
	}

	.valuation-result-container>.valuation-result {
		float: left;
		width: 134px;
		height: 319px;
		margin-left: 45px;
		margin-right: 45px;
	}

	.valuation-result-container>.relative_result {
		background: url(../../assets/img/report/relative-result@3x.png);
		background-size: cover;
	}

	.valuation-result-container>.absolute_result {
		background: url(../../assets/img/report/absolute-result@2x.png);
		background-size: cover;
	}

	.valuation-result-container>.liquidity_ratio {
		background: url(../../assets/img/report/liquidity-radio@2x.png);
		background-size: cover;
	}

	.valuation-result-container>.revise_result {
		background: url(../../assets/img/report/revise-result@2x.png);
		background-size: cover;
	}

	.valuation-result>img {
		margin-left: 62px;
		margin-top: 34px;
	}

	.valuation-result>p:nth-of-type(1) {
		/*width:81px;*/
		width: 60%;
		margin: 0 auto;
		margin-top: 183px;
		font-size: 16px;
		color: rgba(40, 40, 40, 1);
		line-height: 30px;
		text-align: center;
	}

	.valuation-result>p:nth-of-type(2) {
		margin: 0 auto;
		margin-top: 20px;
		width: 110px;
		height: 16px;
		font-size: 17px;
		color: rgba(40, 40, 40, 1);
		line-height: 30px;
		text-align: center;
		font-weight: bold;
		word-break: break-word;
	}

	.img_pageSeven_arrow {
		width: 651px;
		display: block;
		margin: 0 auto;
		margin-top: 28px;
	}

	.valuation-result-main {
		width: 746px;
		height: 136px;
		margin: 0 auto;
		margin-top: 63px;
		border: 3px solid #0478fc;
		border-radius: 10px;
	}

	.valuation-result-main>p:nth-of-type(1) {
		height: 27px;
		margin-top: 25px;
		font-size: 27px;
		color: rgba(40, 40, 40, 1);
		line-height: 27px;
		text-align: center;
	}

	.valuation-result-main>p:nth-of-type(2) {
		height: 29px;
		margin-top: 31px;
		font-size: 30px;
		color: #0478fc;
		line-height: 30px;
		text-align: center;
	}

	.page-seven>p {
		padding: 0 120px;
		margin-top: 116px;
		font-size: 16px;
		color: rgba(40, 40, 40, 1);
		line-height: 16px;
	}

	.page-seven>.explain-container {
		width: 963px;
		height: 78px;
		padding: 30px 23px 30px 20px;
		margin-top: 33px;
		background: rgba(247, 247, 247, 1);
		font-size: 16px;
		color: rgba(40, 40, 40, 1);
		line-height: 30px;
	}

	.page-seven>.explain-container>span {
		display: inline-block;
		float: left;
		width: 10px;
	}

	.page-seven>.explain-container>ul {
		float: left;
	}


	/*第八页*/
	#incomeForecast,
	#netProfitForecast {
		width: 1008px;
		height: 520px;
		margin: 0 auto;
	}

	.bottom_explain {
		width: 1008px;
		height: 70px;
		margin: 0 auto;
		background: rgba(247, 247, 247, 1);
	}

	.bottom_explain>span {
		display: inline-block;
		float: left;
		width: 24px;
		height: 24px;
		margin-top: 23px;
		margin-left: 16px;
		margin-right: 10px;
	}

	.bottom_explain>p {
		float: left;
		font-size: 16px;
		color: rgba(51, 51, 51, 1);
		line-height: 70px;
	}


	/*第九页*/

	.table_explain {
		width: 1008px;
		margin: 0 auto;
		overflow: hidden;
	}

	.sovency {
		margin-top: 62px;
	}

	.asset-liability-ratio {
		margin-top: 52px;
	}

	.sovency_table,
	.profitability_table,
	.growUp_table,
	.operational_table {
		float: left;
		width: 500px;
		height: 400px;
	}

	.profitability_table,
	.operational_table {
		height: 450px;
	}

	.sovency_table>div,
	.profitability_table>div,
	.growUp_table>div,
	.operational_table>div {
		width: 100%;
		height: 100%;
	}

	.table_explain>.right {
		float: right;
		width: 420px;
		/*height: 380px;*/
		height: 396px;
		padding: 0 24px;
		background: rgba(247, 247, 247, 1);
	}

	.grade {
		position: relative;
		float: right;
		/* height: 20px; */
		margin-top: 20px;
		overflow: hidden;
		height: 22px;
		width: 91px;
	}

	.grade img.battery {
		margin-right: 6px;
		height: 22px;
		width: 40px;
	}

	.grade>ul {
		position: absolute;
		width: 26px;
		height: 20px;
		top: 0;
		left: 6px;
	}

	.grade>ul>li {
		float: right;
		width: 16%;
		height: 49%;
		margin: 12% 2%;
		background: #97CA5B;
	}

	.grade>p {
		display: inline-block;
		float: right;
		height: 20px;
		font-size: 16px;
		color: rgba(40, 40, 40, 1);
		line-height: 22px;
		font-weight: bold;
	}

	.table_explain>.right>h4 {
		margin-top: 44px;
		font-size: 18px;
		color: rgba(40, 40, 40, 1);
		line-height: 24px;
		text-indent: 2em
	}

	.table_explain>.right>p {
		font-size: 16px;
		color: rgba(51, 51, 51, 1);
		line-height: 28px;
		text-align: justify;
		text-indent: 2em;
		margin: 12px 0 0;
	}


	/*第十页*/

	.table_explain.profitability {
		height: 490px;
		margin-top: 50px;
	}

	.table_explain>.profitability_explain {
		width: 410px;
		height: 450px;
		padding: 0 20px;
	}


	/*第十一页*/

	.table_explain.growUp {
		height: 450px;
		margin-top: 60px;
	}

	.table_explain>.growUp_explain {
		width: 420px;
		height: 450px;
		padding: 0 20px;
	}


	/*第十二页*/

	.table_explain.operational {
		height: 490px;
		margin-top: 60px;
	}

	.table_explain>.operational_explain {
		width: 418px;
		height: 450px;
		padding: 0 16px;
	}

	/*第十三页*/

	.page-thirteen .imgUl {
		width: 100%;
		height: auto;
		margin: 8% auto 0;
	}

	.page-thirteen .imgUl>li {
		width: 1008px;
		height: auto;
		list-style-type: none;
		margin: 0 auto;
		margin-bottom: 6%;
		overflow: hidden;
	}

	.page-thirteen .imgUl>li>.liDivImg {
		float: left;
		width: 170px;
		height: 170px;
		margin-left: 4%;
		margin-right: 56px;
		text-align: center;
		border: 3px solid #0478fc;
		border-radius: 90px;
	}

	#indicatorContainer2 {
		border: 3px solid #FD865A;
	}

	#indicatorContainer3 {
		border: 3px solid #775BFF;
	}

	#indicatorContainer4 {
		border: 3px solid #00BF8C;
	}

	.page-thirteen .imgUl>li>p {
		float: left;
		width: 70%;
		margin-top: 1%;
		font-size: 18px;
		color: #282828;
		line-height: 2;
		text-indent: 2em;
		cursor: Default;
		text-align: justify;
	}

	.page-thirteen .imgUl>li>h3 {
		width: 70%;
		float: left;
		font-size: 20px;
		text-align: left;
		margin-top: 2%;
		cursor: default;
		font-weight: bold;
	}

	.clear {
		clear: both;
	}


	/*附件*/

	.page-content {
		padding: 116px 110px 0 110px;
	}

	.enclosure-title {
		height: 26px;
		margin-bottom: 26px;
	}

	.enclosure-title>span {
		font-size: 26px;
		line-height: 26px;
		height: 26px;
		display: block;
		float: left;
	}

	.enclosure-title>span.unit {
		float: right;
	}

	.enclosure-title>p {
		height: 26px;
		font-size: 26px;
		line-height: 1;
		text-align: center;
		width: 852px;
		float: left;
	}

	.enclosure-title>p span {
		margin-top: -5px;
	}


	/*表格*/

	table {
		width: 1008px;
		margin: 0 auto;
	}

	table tr {
		height: 43px;
	}

	table.liaTableInfo tr {
		height: 40px;
	}

	table>thead>tr {
		background: #0478fc;
		border: 1px solid #000;
	}

	table>thead>tr>th {
		font-size: 18px;
		color: #fff;
		line-height: 44px;
		border: 1px solid #000;
		border-bottom-color: #0478fc;
	}

	table tr td {
		border: 1px solid #333;
	}

	table>tbody>tr:first-of-type>td {
		border-top: none;
	}

	table>tbody>tr>td:first-of-type {
		padding-left: 30px;
		text-align: left;
	}

	table>tbody>tr>td:nth-child(n+2) {
		padding-right: 50px;
		text-align: right;
	}


	/*///////////*/

	table.table {
		width: 100%;
		margin-bottom: 70px;
	}

	table.table>thead {
		text-align: center;
		font-size: 16px;
		color: #282828;
		font-weight: bold;
		background: #FFE178;
	}

	table.table tbody {
		border-left: 1px solid black;
	}


	/* 表格*/

	table>tbody>tr.trOne>td:first-of-type {
		padding-left: 20px;
	}

	table>tbody>tr.trTwo>td:first-of-type {
		padding-left: 34px;
		font-size: 14px;
		color: #282828;
	}

	table>tbody>tr.trThree>td:first-of-type {
		padding-left: 48px;
		font-size: 14px;
		color: #282828;
	}

	table>tbody>tr.trOne {
		font-size: 16px;
		font-weight: bold;
		color: #282828;
		font-weight: bold;
	}

	table th {
		width: 25%;
	}

	table.profitTableInfo tr {
		height: 36px;
	}

	.formula-container>h3 {
		font-size: 20px;
		line-height: 1;
		color: #282828;
		margin-bottom: 20px;
		margin-top: 26px;
	}

	.formula-container>ul>li {
		overflow: hidden;
		margin-bottom: 6px;
	}

	.formula-container>ul>li>img {
		width: 9px;
		height: 9px;
		float: left;
		margin-right: 13px;
		margin-top: 6px;
	}

	.formula-container>ul>li>.formula-tit {
		width: 984px;
		float: left;
		margin-bottom: 4px;
	}

	.formula-tit img {
		margin-top: 8px;
		margin-bottom: 10px;
	}

	.formula-container>ul>li>.formula-tit>p {
		line-height: 34px;
		color: #282828;
		font-size: 18px;
	}

	.formula-container>ul>li>.formula-tit>h4 {
		line-height: 34px;
		color: #282828;
		font-size: 20px;
	}

	.formula-container>ul>li>.formula-tit>h4>span {
		display: inline-block;
	}

	.formula-container>ul>li>p {
		font-size: 18px;
		line-height: 34px;
		color: #282828;
	}

	.page-last>img {
		width: 900px;
		height: 376px;
		margin: 0px auto 51px;
		display: block;
	}

	.flow-statement {
		width: 1008px;
		margin: 0 auto;
	}

	.flow-statement>img {
		display: block;
		width: 349px;
		height: 114px;
		margin: 0 auto 47px;
	}

	.flow-statement>ul>li>div.statement-tit {
		height: 20px;
		width: 428px;
		margin: 54px auto 35px;
		font-size: 0;
		position: relative;
	}

	.statement-tit>span.xian {
		width: 135px;
		height: 1px;
		display: block;
		position: absolute;
		top: 0;
		margin-top: 9.5px;
		background: #0478fc;
	}

	.statement-tit>span.xian:first-of-type {
		left: 2px;
	}

	.statement-tit>span.xian:last-of-type {
		right: 0;
	}

	.statement-tit>img {
		width: 12px;
		height: 12px;
		float: left;
		margin-top: 4px;
	}

	.statement-tit>img:first-of-type {
		margin-left: 135px;
	}

	.statement-tit>p {
		width: 136px;
		height: 20px;
		line-height: 20px;
		font-size: 20px;
		color: #282828;
		float: left;
		background: #FFF;
		text-align: center;
	}

	.statement-main {
		width: 977px;
		padding: 17px 20px 16px 20px;
		background: #E8F4FF;
		font-size: 16px;
		color: #282828;
		line-height: 30px;
		text-indent: 2em;
		text-align: justify;
	}


	.base {
		height: 9em;
		left: 50%;
		margin: -7.5em;
		padding: 3em;
		position: absolute;
		top: 50%;
		width: 9em;
		transform: rotateX(45deg) rotateZ(45deg);
		transform-style: preserve-3d;
	}

	.cube,
	.cube:after,
	.cube:before {
		content: '';
		float: left;
		height: 3em;
		position: absolute;
		width: 3em;
	}


	/* Top */

	.cube {
		background-color: #cb9f4f;
		position: relative;
		transform: translateZ(3em);
		transform-style: preserve-3d;
		transition: .25s;
		box-shadow: 13em 13em 1.5em rgba(0, 0, 0, 0.1);
		animation: anim 1s infinite;
	}

	.cube:after {
		background-color: #BE9853;
		transform: rotateX(-90deg) translateY(3em);
		transform-origin: 100% 100%;
	}

	.cube:before {
		background-color: #AD8E55;
		transform: rotateY(90deg) translateX(3em);
		transform-origin: 100% 0;
	}

	.cube:nth-child(1) {
		animation-delay: 0.05s;
	}

	.cube:nth-child(2) {
		animation-delay: 0.1s;
	}

	.cube:nth-child(3) {
		animation-delay: 0.15s;
	}

	.cube:nth-child(4) {
		animation-delay: 0.2s;
	}

	.cube:nth-child(5) {
		animation-delay: 0.25s;
	}

	.cube:nth-child(6) {
		animation-delay: 0.3s;
	}

	.cube:nth-child(7) {
		animation-delay: 0.35s;
	}

	.cube:nth-child(8) {
		animation-delay: 0.4s;
	}

	.cube:nth-child(9) {
		animation-delay: 0.45s;
	}

	@keyframes anim {
		50% {
			transform: translateZ(0.5em);
		}
	}

	#loading {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.5);
		display: none;
	}

	.result-explain-container {
		overflow: hidden;
		margin: 33px auto 0;
		background: rgba(247, 247, 247, 1);
		font-size: 16px;
		color: rgba(40, 40, 40, 1);
	}

	.result-explain-container>ul>li>p {
		width: 910px;
		line-height: 1.875;
	}

	.result-explain-container>ul {
		width: 896px;
		min-width: 896px;
		float: left;
	}

	.result-explain-container>p {
		line-height: 30px;
		float: left;
	}

	.result-explain-container>ul>li>label {
		font-size: 16px;
		color: #282828;
		float: left;
		line-height: 1.9;
	}

	table>tbody>tr>td.two {
		padding-left: 56px;
	}

	table>tbody>tr>td.three {
		padding-left: 70px;
	}

	table>tbody>tr>td.four {
		padding-left: 112px;
	}


	/* 专业价值诊断 */

	.appraisement {
		width: 100%;
		height: 66px;
		line-height: 72px;
		font-size: 14px;
		background: url('../../assets/img/startup/bolang.jpg') no-repeat;
	}

	.appraisement p {
		width: 532px;
		margin: 0 auto;
	}

	.appraisement p a {
		color: #0478FC;
		text-decoration: underline;
	}

	.synthesisScore {
		margin-top: 24px;
	}

	.synthesisScore .companyName {
		font-size: 18px;
		font-weight: bold;
		color: rgba(40, 40, 40, 1);
		text-align: center;
		margin: 11px 0 24px;
	}

	#companyScore {
		width: 342px;
		height: 264px;
		margin: 0 auto;
	}

	.tagTitle {
		font-size: 22px;
		font-weight: bold;
		color: rgba(40, 40, 40, 1);
	}

	.indexScore {
		width: 95%;
		margin: 18px 0 32px 42px;
		overflow: hidden;
	}

	.indexScore>li {
		float: left;
		width: 108px;
		margin-right: 60px;
	}

	.indexScore>li:last-child {
		margin-right: 0;
	}

	.indexScore p {
		font-size: 18px;
		color: rgba(40, 40, 40, 1);
		line-height: 30px;
		text-align: center;
		margin-top: 28px;
	}

	.specificSuggest p:first-child {
		font-size: 18px;
		font-weight: bold;
		color: rgba(2, 119, 252, 1);
		margin-top: 25px;
	}

	.specificSuggest p:last-child {
		font-size: 16px;
		font-weight: 400;
		color: rgba(40, 40, 40, 1);
		margin-top: -12px;
		line-height: 25px;
	}

	.specificSuggest p span {
		display: block;
		text-indent: 2em;
	}

	.specificSuggest p i {
		position: relative;
		top: 24px;
		left: 33px;
	}

	.specificSuggest p span:nth-of-type(1) {
		text-indent: 4.5em;
	}

	.majorDiagnose>div {
		height: 976px;
	}

	.majorDiagnose>span:nth-of-type(3) {
		margin-top: 56px;
	}

	.majorDiagnose>span:nth-of-type(5) {
		margin-top: 127px;
	}

	.majorDiagnose>span:nth-of-type(6) {
		margin-top: 236px;
	}

	.majorDiagnose>span:nth-of-type(7) {
		margin-top: 57px;
	}


	/*股交所诊断报告*/


	/* 股交所logo */

	#page-stock>.introduce-img {
		display: block;
		position: absolute;
		top: 0;
	}

	.icon_ {
		width: 48px;
		height: 48px;
		float: left;
		margin-right: 25px;
	}

	.dupontTitle p,
	.financialConditionTitle p,
	.operateTitle p,
	.growthTitle p,
	.profitTitle p,
	.debtPayTitle p,
	.product_Title p,
	.income_Title p,
	.expenditure_Title p,
	.skillTitle p,
	.riskTitle p,
	.teamTitle p,
	.industryTitle p,
	.relativeValuationTitle p,
	.absoluteValuationTitle p,
	.flowDiagnoseTitle p,
	.financeAnalyzeTitle p,
	.situationTitle p,
	.structureTitle p,
	.groupTitle p,
	.baseInfoTitle p,
	.mainBusinessTitle p,
	.valueResultTitle p {
		line-height: 48px;
		font-size: 30px;
		font-weight: bold;
		color: #121212;
	}

	.valueResultPic {
		width: 698px;
		height: 584px;
		background: url(../../assets/img/guzhi/resultPic.png)center no-repeat;
		background-size: cover;
		margin: 18px 0 0 163px;
		overflow: hidden;
	}

	.valueResultPic p {
		width: 448px;
		height: 30px;
		font-size: 32px;
		font-weight: bold;
		color: #0478fc;
		line-height: 31px;
		margin: 145px auto 0;
		text-align: center;
	}

	.valueResultCont {
		text-align: center;
	}

	.valueResultCont h6 {
		font-size: 16px;
		font-weight: 400;
		color: #666666;
		line-height: 31px;
		margin: 37px auto;
	}

	.valueResultCont h6 span {
		line-height: 100%;
		margin-top: -2px;
	}

	.valueResultCont .resultDes {
		padding: 0 90px;
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 34px;
		text-align: left;
		text-indent: 36px;
	}

	.baseInfo {
		margin-top: 50px;
	}

	.icon_1 {
		height: 50px;
	}

	.situationCont ul {
		padding: 25px 73px 0;
	}

	.baseInfoCont {
		overflow: hidden;
		padding: 25px 0;
	}

	.situationCont {
		height: 100px;
	}

	.situationCont ul li,
	.baseInfoCont ul li {
		width: 50%;
		float: left;
	}

	.situationCont ul li span,
	.baseInfoCont ul li span {
		font-size: 18px;
		font-weight: 400;
		color: #010000;
		line-height: 34px;
	}

	.icon_2 {
		width: 50px;
	}

	.mainBusinessCont p {
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
		padding: 0 110px 0 73px;
		margin: 20px 0 0 0;
	}

	.productAndGroup {
		margin: 25px 0 0 0;
	}

	.product,
	.group {
		float: left;
		width: 50%;
	}

	.productAndGroup ul {
		padding: 25px 73px 0;
	}

	.productAndGroup li {
		font-size: 18px;
		font-weight: 400;
		color: #333333;
		line-height: 34px;
	}

	#stockStructurePie {
		width: 800px;
		height: 400px;
		margin: 20px auto;
	}

	#flowDiagnose_BaseInfo,
	#financeAnalyze_BaseInfo {
		width: 500px;
		height: 350px;
		margin: 20px auto;
	}

	.industryTitle,
	.income_Title {
		margin: 56px 0 0 116px;
	}

	.financialConditionTitle,
	.dupontTitle,
	.growthTitle,
	.operateTitle,
	.profitTitle,
	.debtPayTitle,
	.riskManageDesc,
	.expenditure_Title,
	.absoluteValuation {
		margin: 116px 0 0 116px;
	}

	.enterpriseContent .enterpriseCompeChart {
		width: 33%;
	}

	.enterpriseContent {
		margin-top: 20px;
	}

	.riskManageDesc p {
		padding: 0 77px 0 0;
		font-size: 18px;
		line-height: 1.875;
		text-indent: 2em;
		text-align: justify;
	}

	.formula-container span {
		float: left;
		font-size: 14px;
		margin: 7px 10px 0 0;
		color: #0478FC;
	}


	/* 新目录 */

	.page-catalogue h2 {
		font-size: 48px;
		font-weight: bold;
		color: #0F060B;
		margin: 172px auto 69px;
		text-align: center;
		line-height: 1;
	}

	.page-catalogue .generalize>div {
		width: 980px;
		height: 54px;
		margin: 23px auto 0px;
	}

	.page-catalogue .generalize>div p:first-child {
		font-size: 32px;
	}

	.page-catalogue .generalize p {
		float: left;
		font-size: 24px;
		font-weight: bold;
		color: #0F060B;
		line-height: 54px;
		margin-right: 11px;
	}

	.page-catalogue .generalize>div>div {
		width: 6px;
		height: 54px;
		background: #0478FC;
		float: left;
		margin: 0 28px 0 0;
	}

	.page-catalogue .generalize .dottedLine {
		float: left;
		height: 2px;
		width: 736px;
		margin: 14px 20px 0;
		background: url(../../assets/img/report/catalogue_.png)left center repeat;
	}

	.page-catalogue .generalize div>.dottedLine {
		margin: 28px 20px 0;
	}

	.page-catalogue .generalize .pageNumber {
		float: left;
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 100%;
		margin-top: 4px;
	}

	.page-catalogue .generalize div>.pageNumber {
		margin-top: 19px;
	}

	.page-catalogue .generalize ul {
		margin: 0 0 0 194px;
	}

	.page-catalogue .generalize ul li {
		height: 30px;
		margin: 9px 0 13px 0;
		width: calc(980px - 64px);
	}

	.page-catalogue .generalize ul li p {
		font-size: 18px;
		font-weight: 400;
		color: #423838;
		line-height: 30px;
	}

	.page-catalogue .generalize ul li .dottedLine {
		width: 760px;
	}

	.page-catalogue .generalize ul.flowChart li .dottedLine {
		width: 797px;
	}

	.page-one>.artFont {
		width: 64px;
		height: 28px;
		margin: 0;
		position: absolute;
		top: 6.2%;
		right: 393px;
	}

	.page-stamp .artFont {
		width: 94px;
		height: 128px;
		position: absolute;
		top: -22px;
		left: -88px;
	}

	.stamp {
		width: 68%;
		line-height: 41px;
		margin: 10% auto;
		font-size: 18px;
		font-weight: 400;
		text-indent: 2em;
		margin-top: 31%;
		text-align: justify;
		color: #050407;
	}

	.detail_result {
		display: flex;
		flex-direction: row;
		justify-content: center;
		width: 100%;
		margin-top: 54px;
		/* overflow: hidden; */
	}

	.detail_result h4 {
		float: left;
		height: 80px;
		margin-top: 6px;
		border-top: 3px solid #4393FF;
		border-bottom: 3px solid #4393FF;
		font-size: 38px;
		color: #4393FF;
		line-height: 80px;
		padding: 0 34px;
		font-weight: bold;
	}

	.stamptime {
		width: 68%;
		margin: 10% auto;
		font-size: 20px;
		text-align: right;
		line-height: 39px;
		margin-top: 29%;
	}

	.stamptime>p {
		font-size: 22px;
		margin-top: 29px;
		line-height: normal;
	}

	.detail_result>div {
		position: relative;
	}

	.pageNum {
		// width: 57px;
		position: absolute;
		color: #000;
		line-height: 100%;
		font-size: 22px;
		left: 50%;
		margin-left: -27px;
		bottom: 37px;
	}

	.pageNum p {
		color: #0478fc;
		display: inline;
		margin: 0px 5px 0 0;
		font-size: 22px;
	}

	.pageNum span {
		margin-top: -2.5px;
	}

	.flowDiagnose,
	.financeAnalyze {
		float: left;
	}

	.statement-main div {
		margin: 30px 0 0 118px;
		overflow: hidden;
	}

	.statement-main div img {
		float: left;
		width: 138px;
		height: 138px;
		margin: 0 36px 20px 0;
	}

	.statement-main div ul {
		margin: 4px 0 0 0;
	}

	.statement-main div ul li {
		font-size: 18px;
		font-weight: 400;
		color: #282828;
		line-height: 30px;
	}

	.statement-main div ul li span {
		width: 72px;
		text-indent: 0;
		text-align-last: justify;
		text-align: justify;
		text-justify: distribute-all-lines;
	}

	.circleComponent {
		float: left;
		margin-right: 20px;
		font-size: 24px;
		color: #0478fc;
	}

	/deep/ .circleComponent {
		margin: 8px;
	}
</style>